import CustomStore from 'devextreme/data/custom_store';
import { DataStore, Predicates } from "aws-amplify";
import { Period, PeriodType, Device, UserRole, PeriodMeteringPoint} from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, dateToAWSDateTime} from "../common/customDataStore";
import {mapDeviceData} from "./devicesStore";
import {mapTourData} from "./toursStore";

export const mapPeriodData = async (period, resolveDevices=false, resolveTours=false) => {
    let {
        id,
        type,
        year,
        comment,
        createdAt,
        updatedAt,
        devices,
        tours
    } = period;
    if (devices && resolveDevices) {
        devices = await devices.toArray();
        devices = await Promise.all(devices.map(async (d) => mapDeviceData(await d.device)));
    } else {
        devices = null;
    }
    if (tours && resolveTours) {
        tours = await tours.toArray();
        tours = await Promise.all(tours.map((t) => mapTourData(t)));
    }
    return {
        id,
        type,
        year,
        comment,
        devices,
        tours,
        createdAt,
        updatedAt
    };
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];
    // to filter by type
    if (filterOps?.type) {
        filtersList.push(c.type.eq(filterOps.type));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    return c => c.type.eq(filterOps.type);
}

export const periodsStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let periods = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            periods = await DataStore.query(Period, (period) => period.type.eq(filter?.type));
            // map periods
            periods = await Promise.all(periods.map((period) => mapPeriodData(period, true, true)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            periods = await DataStore.query(Period,
                c => applySelectedFiltering(c,filter));
            periods = await Promise.all(periods.map((ret) => mapPeriodData(ret, true, true)));
        } else { // paging
            const page = Math.floor(skip/take);
            periods = await DataStore.query(Period,
                filter ? c => applyDataStoreFiltering(c,filter) : Predicates.All, {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map periods
            periods = await Promise.all(periods.map((period) => mapPeriodData(period, true, true)));
        }
        return {
            data: periods
        }
    },
    remove: (key) => {
        return DataStore.query(Period, key)
            .then(async (original) => {
                    // delete manyToMany relations first
                    let periodMeteringPoints = [];
                    let mpRelations = await DataStore.query(PeriodMeteringPoint, (pmp) => pmp.periodID.eq(key));
                    for(const relation of mpRelations) {
                        periodMeteringPoints.push(DataStore.delete(relation)
                            .then(() =>
                                console.log(`Relation ${relation.id} removed`)
                            ).catch((error) => {
                            console.error(`Relation ${relation.id} not removed`);
                            notify(`Relation ${relation.id} not removed`, "error", 3000);
                            throw Error(error);
                        }));
                    }
                    await Promise.all(periodMeteringPoints);
                    // delete the period
                    DataStore.delete(original)
                        .then(() =>
                            notify(`Periode ${original.id} wurde erfolgreich gelöscht`, "success", 3000)
                        ).catch((error) => {
                            notify(`Periode ${original.id} wurde nicht gelöscht`, "error", 3000)
                            throw Error(error);
                        })
                }
            )
            .catch((error) => {
                notify(`Keine Periode mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: async (data) => {
        const id = uuidv4();
        try {
            const period = await DataStore.save(
                new Period({
                    id: id,
                    type: data.type,
                    year: data.year,
                    comment: data.comment,
                    //devices: data.devices,
                    //tours: data.tours,
                    status: data.status,
                    createdAt: dateToAWSDateTime(new Date()),
                    updatedAt: dateToAWSDateTime(new Date()),
                    groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                    groupWrite: [UserRole.ADMIN],
                }));
            // save the model that links a period with the devices
            let periodMeteringPoints = [];
            for(const mp of data.meteringPoints) {
                periodMeteringPoints.push(DataStore.save(
                    new PeriodMeteringPoint({
                        periodID: period.id,
                        meteringPointID: mp.id,
                        createdAt: dateToAWSDateTime(new Date()),
                        updatedAt: dateToAWSDateTime(new Date()),
                        groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                        groupWrite: [UserRole.ADMIN],
                    })
                ));
            }
            await Promise.all(periodMeteringPoints);
            // for checking
            //const qresult = await DataStore.query(PeriodDevices, Predicates.ALL);
            //console.log(qresult);
            notify(`Periode wurde erfolgreich angelegt`, "success", 3000);
        } catch(err) {
            console.error(err);
            notify(`Periode wurde nicht angelegt`, "error", 3000);
            throw Error(err);
        }
    },
    update: (key, data) => {
        return DataStore.query(Period, key)
            .then((original) => DataStore.save(
                Period.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        updated[key] = value;
                    }
                })).then(() => notify(`Periode wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Periode wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Keine Periode mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(Period, key)
            .then(async (original) => await mapPeriodData(original))
            .catch((error) => {
                notify(`Keine Periode mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapPeriodData(elem);
        switch(data.opType) {
            case "UPDATE":
                periodsStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                periodsStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                periodsStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}