import CustomStore from 'devextreme/data/custom_store';
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { Tour, TourTask, UserRole, Period, PeriodMeteringPoint, MeteringPoint, TourTaskStatus} from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, dateToAWSDateTime, dateToAWSDate} from "../common/customDataStore";
import {mapPeriodData} from "./periodsStore";

export const mapTourData = async (tour) => {
    let {
        id,
        buchNr,
        date,
        comment,
        assignedToUserId,
        accomplishedAt,
        status,
        createdAt,
        updatedAt,
        periodId,
        period,
        tasks
    } = tour;
    period = await period;
    period = period ? await mapPeriodData(period,true) : null;

    if (tasks) {
        tasks = await tasks.toArray();
    }
    return {
        id,
        buchNr,
        periodId,
        period,
        date,
        comment,
        assignedToUserId,
        accomplishedAt,
        status,
        tasks,
        createdAt,
        updatedAt
    };
};

const applySorting = (sortProxy, sortingOps) => {
    if (sortingOps) {
        sortingOps.forEach((op) => {
            const funcName = op.selector;
            sortProxy[funcName](op.desc ? SortDirection.DESCENDING : SortDirection.ASCENDING)
        });
    }
    return sortProxy;
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];
    // to filter by periodId
    if (filterOps?.periodId) {
        filtersList.push(c.periodId.eq(filterOps.periodId));
    }
    // to filter by assignedToUserId
    if (filterOps?.assignedToUserId) {

        filtersList.push(c.assignedToUserId.eq(filterOps.assignedToUserId));
    }
    // to filter by assignedToUserId
    if (filterOps?.status) {
        filtersList.push(c.status.eq(filterOps.status));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    if (filterOps) {
        return c.and(c => mapFilters(c,filterOps));
    }
}

export const toursStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, sort, isLoadingAll} = loadOptions;
        let tours = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            tours = await DataStore.query(Tour, (tour) => Predicates.All);
            // map tours
            tours = await Promise.all(tours.map((tour) => mapTourData(tour)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            tours = await DataStore.query(Tour,
                c => applySelectedFiltering(c,filter));
            tours = await Promise.all(tours.map((ret) => mapTourData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            tours = await DataStore.query(Tour,
                filter ? c => applyDataStoreFiltering(c,filter) : Predicates.All, {
                    page: page,
                    limit: take ? take : 50,
                    sort: s => applySorting(s, sort)
                } );
            // map tours
            tours = await Promise.all(tours.map((tour) => mapTourData(tour)));
        }
        return {
            data: tours
        }
    },
    remove: (key) => {
        return DataStore.query(Tour, key)
            .then(async (original) => {
                    // delete the tour
                    DataStore.delete(original)
                        .then(() =>
                            notify(`Tour ${original.id} wurde erfolgreich gelöscht`, "success", 3000)
                        ).catch((error) => {
                            notify(`Tour ${original.id} wurde nicht gelöscht`, "error", 3000)
                            throw Error(error);
                        })
                }
            )
            .catch((error) => {
                notify(`Keine Tour mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: async (data) => {
        const id = uuidv4();
        try {
            const tour = await DataStore.save(
                new Tour({
                    id: id,
                    buchNr: data.buchNr,
                    periodId: data.periodId,
                    date: dateToAWSDate(data.date),
                    comment: data.comment,
                    assignedToUserId: data.assignedToUserId,
                    accomplishedAt: dateToAWSDateTime(data.accomplishedAt),
                    status: data.status,
                    createdAt: dateToAWSDateTime(new Date()),
                    updatedAt: dateToAWSDateTime(new Date()),
                    groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                    groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN],
                }));
            notify(`Tour wurde erfolgreich angelegt`, "success", 3000);
            // generate aumoatically Tourtasks from Meteringpoints, selected in the period and with this buchNr
            const period = await DataStore.query(Period, data.periodId);
            console.log("[CUSTOM]", period)
            const periodMeteringPoints = await DataStore.query(PeriodMeteringPoint, c => c.periodID.eq(data.periodId));
            console.log("[CUSTOM]", periodMeteringPoints)
            const meteringPoints = (await Promise.all(periodMeteringPoints.map(async pmp => await DataStore.query(MeteringPoint, pmp.meteringPointID)))).filter(mp => mp.buchNr === data.buchNr);
            console.log("[CUSTOM]", meteringPoints)
            const tourTasks = await Promise.all(meteringPoints.map(mp => {
                return DataStore.save(new TourTask({
                    tourId: id,
                    buchNrLfd: mp.buchNrLfd,
                    meteringPointId: mp.id,
                    type: period.type,
                    date: dateToAWSDate(data.date),
                    status: TourTaskStatus.CREATED,
                    groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                    groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN],
                }))
            }));
            notify(`Arbeitspakete wurde erfolgreich angelegt`, "success", 3000);
            console.log(tourTasks)
        } catch(err) {
            console.error(err);
            notify(`Tour wurde nicht angelegt`, "error", 3000);
            throw Error(err);
        }
    },
    update: (key, data) => {
        return DataStore.query(Tour, key)
            .then((original) => DataStore.save(
                Tour.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        switch (key) {
                            case "date":
                                updated[key] = dateToAWSDate(value);
                                break;
                            case "accomplishedAt":
                                updated[key] = dateToAWSDateTime(value);
                                break;
                            default:
                                updated[key] = value;
                                break;
                        }
                    }
                })).then(() => notify(`Tour wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Tour wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Keine Tour mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(Tour, key)
            .then(async (original) => await mapTourData(original))
            .catch((error) => {
                notify(`Keine Tour mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapTourData(elem);
        switch(data.opType) {
            case "UPDATE":
                toursStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                toursStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                toursStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}