import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting, Button as DataGridButton
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import {
    Item, TabbedItem, TabPanelOptions, Tab, RequiredRule, EmptyItem
} from 'devextreme-react/form';
import SelectBox from "devextreme-react/select-box";
import AuthContext from "../../contexts/AuthContext";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore } from "aws-amplify";
import { Tour, TourTask, TourTaskType, UserRole, Device } from "../../models";
import {useTranslation} from "react-i18next";
import {mapTourTaskData, applySorting, applyDataStoreFiltering} from "../../data/tourTasksStore";
import {usersStore} from "../../data/usersStore";
import {mapTourData} from "../../data/toursStore";
import {uploadImage} from "../../common/customDataStore";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering} from "../../common/customDataStore";
import PageHeader from "../PageHeader/PageHeader";
import ImagesItem from "../ImagesItem/ImagesItem";
import { TourTaskStatus } from "../../models";


const renderTourTaskDetails = (cellInfo) => {
    const deviceId = cellInfo.data?.meteringPoint?.deviceId;
    const year = cellInfo.data?.meteringPoint?.letztesEichJahr;
    const address = cellInfo.data?.meteringPoint?.object?.address;
    const customer = cellInfo.data?.meteringPoint?.customer;
    return (
        cellInfo.data ?
            <div className="TourTaskDescription">
                <div className="header">
                    <div className="deviceId">
                        {deviceId ? `${deviceId}` : "keine Daten"}
                    </div>
                    <div className="shortSummary">({year ? `${year}` : "keine Daten"})
                    </div>
                </div>
                <div className="address">{address ? `${address.street} ${address.streetNr}` : "keine Daten"}</div>
                <div className="customer">{customer ? `${customer.lastName} ${customer.firstName}` : "keine Daten"}</div>
            </div>
            :
            <div className="TourDescription">
                <p>{"keine Daten"}</p>
            </div>
    );
};

const renderStatus = (cellInfo) => {
    const status = cellInfo.data.status;
    return (
            <div className="TourTaskStatus">
                <Button
                    className={`task-button ${status === TourTaskStatus.FURTHER_ACTION ? "furtherAction" : "notSelected"}`}
                    stylingMode="outlined"
                    elementAttr={{
                        style:"border-radius:50%; padding:0.5em;"
                    }}
                    icon="fa-solid fa-triangle-exclamation"
                />
                <Button
                    className={`task-button ${status === TourTaskStatus.FURTHER_ACTION ? "noCustomer" : "notSelected"}`}
                    stylingMode="outlined"
                    elementAttr={{
                        style:"border-radius:50%; padding:0.5em;"
                    }}
                    icon="fa-solid fa-user-slash"
                    CornerRadius="50"
                />
                <Button
                    className={`task-button ${status === TourTaskStatus.FURTHER_ACTION ? "done" : "notSelected"}`}
                    stylingMode="outlined"
                    elementAttr={{
                        style:"border-radius:50%; padding:0.5em;"
                    }}
                    icon="fa-solid fa-circle-check"
                />
            </div>
    );
};

function TourTaskTechnicianView({periodId, tourId}) {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const tourTaskStatus = t('tourStatus',{ returnObjects: true });
    const tourTaskType = t('tourTaskType',{ returnObjects: true });
    const filteringValues = t('tourTaskTechnicianStatusFilteringValues',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    const [tour, setTour] = useState({});
    const [statusFilter, setStatusFilter] = useState(null);
    const dataGridRef = useRef(null);

    const tourTasksStore = new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
            const {skip, take, filter, sort, isLoadingAll} = loadOptions;
            let tourTasks = [];
            if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
                tourTasks = await DataStore.query(TourTask, c => applyDataStoreFiltering(c,{periodId, tourId}));
                // map tourTasks
                tourTasks = await Promise.all(tourTasks.map((tourTask) => mapTourTaskData(tourTask)));
            } else if (Array.isArray(filter)) { // handle filter for selected rows
                tourTasks = await DataStore.query(TourTask,
                    c => applySelectedFiltering(c,filter));
                tourTasks = await Promise.all(tourTasks.map((ret) => mapTourTaskData(ret)));
            } else { // paging
                const page = Math.floor(skip/take);
                tourTasks = await DataStore.query(TourTask,
                    c => applyDataStoreFiltering(c,{...filter, periodId, tourId}), {
                        page: page,
                        limit: take ? take : 50,
                        sort: s => applySorting(s, sort)
                    } );
                // map tourTasks
                tourTasks = await Promise.all(tourTasks.map((tourTask) => mapTourTaskData(tourTask)));
            }
            //console.log(tourTasks)
            return {
                data: tourTasks
            }
        },
        update: (key, data) => {
            if (data.images) {
                // upload images first
                data.images.forEach((img) => {
                    if (img.isNew === true) {
                        uploadImage(uuidv4(), img.name,
                            img.content,
                            `Image from Buch. Nr. Lfd.: ${data.buchNrLfd}`,
                            key);
                    }
                })
            }
            return DataStore.query(TourTask, key)
                .then((original) => DataStore.save(
                    TourTask.copyOf(original, (updated) => {
                        for(const [key, value] of Object.entries(data)) {
                            updated[key] = value;
                        }
                    })).then(() => notify(`TourTask wurde erfolgreich aktualisiert`, "success", 3000))
                    .catch((error) => {
                        notify(`TourTask wurde nicht aktualisiert`, "error", 3000);
                        throw Error(error);
                    })
                )
                .catch((error) => {
                    notify(`Kein TourTask mit ID ${key} gefunden`, "error", 3000);
                    throw Error(error);
                });
        },
        byKey: (key) => {
            return DataStore.query(TourTask, key)
                .then(async (original) => await mapTourTaskData(original))
                .catch((error) => {
                    notify(`Kein TourTask mit ID ${key} gefunden`, "error", 3000);
                    throw Error(error);
                });
        }
    });

    async function handleSubscriptionEvent(data) {
        try {
            const elem = data.element;
            const transformed_data = mapTourTaskData(elem);
            switch(data.opType) {
                case "UPDATE":
                    tourTasksStore.push([{
                        type: "update",
                        data: transformed_data,
                        key: elem.id}]);
                    break;
                case "INSERT":
                    tourTasksStore.push([{
                        type: "insert",
                        data: transformed_data}]);
                    break;
                case "DELETE":
                    tourTasksStore.push([{ type: "remove", key: elem.id }]);
                    break;
                default: break;
            }
        } catch (err) {
            console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
        }
    }

    const getFilteredTourTaskStatus = (options) => {
        let filter = null;
        return {
            store: tourTaskStatus,
            filter:filter
        };
    }

    const getFilteredTourTaskType = (options) => {
        let filter = null;
        return {
            store: tourTaskType,
            filter:filter
        };
    }

    const devicesOfPeriodStore = new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
            let devices = await DataStore.query(Device, (d) => d.periods.period.id.eq(periodId)).then(devices => {return devices})
            console.log(devices)
            return {
                data: devices
            }
        },
        byKey: (key) => {
            return DataStore.query(Device, key)
                .then((original) => {return original})
                .catch((error) => {
                    throw Error(error);
                });
        }
    });

    const getFilteredUsers = (options) => {
        let filter = [['role', '=', 'Technician']];
        return {
            store: usersStore,
            filter:filter
        };
    }

    const editTourTask = async (event) => {
        if (![UserRole.TECHNICIAN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine TourTasks bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeTourTask = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine TourTasks löschen`);
            event.cancel = true;
            return;
        }
    }

    const onEditingStart = (event) => {
        // set here default values
        event.data.tourId=tourId;
        event.data.accomplishedUserId=userContext.userInfoFromDB.id;
        event.data.accomplishedAt= new Date();
    }

    useEffect(() => {
        /*const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                dataGridRef?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);*/
        DataStore.query(Tour, tourId)
            .then(async (result) => {
                setTour(await mapTourData(result));
            }
            );
        const subscription = DataStore.observe(TourTask).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            //Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.TECHNICIAN, UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="TourTaskTechnicianView">
                <PageHeader
                    headerText={t('tourTaskTechnicianView.header')}
                    backButtonText={t('tourTaskTechnicianView.backButtonText')}/>
                <DataGrid
                    ref={ref => dataGridRef.current = ref}
                    dataSource={tourTasksStore}
                    defaultFilterValue={[]}
                    filterValue={statusFilter ? ["status", "=", statusFilter] : []}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={false}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    id="tourTasks"
                    onRowUpdating={e => editTourTask(e)}
                    onEditingStart={onEditingStart}
                    onRowRemoving={e => removeTourTask(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={false} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={false}
                            allowUpdating={true}
                            allowDeleting={false}
                            >
                            <Popup title={t('tourTaskTechnicianView.popupTitle1')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={1} showValidationSummary={true}>
                                <TabbedItem>
                                    <TabPanelOptions deferRendering={false} />
                                    <Tab title={t('tourTaskTechnicianView.groupItem1')} colCount={2}>
                                        <Item dataField="accomplishedAt" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="accomplishedUserId" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="status" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        <EmptyItem />
                                        <Item dataField="comment" editorType="dxTextArea" editorOptions={{height: "250"}} colSpan={2}/>
                                        <Item
                                            dataField="images"
                                            colSpan={2}/>
                                    </Tab>
                                    <Tab title={t('tourTaskTechnicianView.groupItem2')} colCount={2}>
                                        <Item
                                            dataField="buchNrLfd"
                                            colSpan={1}
                                            editorType="dxNumberBox"
                                            editorOptions={{
                                                min:0,
                                                showSpinButtons:true
                                            }}>
                                        </Item>
                                        <Item dataField="meteringPoint.deviceId" colSpan={1}/>
                                        <Item dataField="date" colSpan={1}/>
                                        <Item dataField="type" colSpan={1}/>
                                    </Tab>
                                </TabbedItem>
                            </Form>
                        </Editing>
                    <Column dataField="buchNrLfd"
                            caption={t('tourTaskTechnicianView.buchNrLfd')}
                            dataType={"number"}
                            allowEditing={false}
                            visible={false}
                            defaultSortOrder="asc"
                            hidingPriority={11}
                            />

                    <Column dataField="date"
                            caption={t('tour TaskTechnicianView.date')}
                            dataType="date"
                            allowEditing={false}
                            visible={false}
                            hidingPriority={6}/>
                    <Column dataField="meteringPoint.deviceId"
                            caption={t('tourTaskTechnicianView.deviceId')}
                            cellRender={(cellInfo) => renderTourTaskDetails(cellInfo)}
                            dataType={"string"}
                            allowEditing={false}
                            minWidth={200}
                            hidingPriority={11}/>
                    <Column dataField="status" caption={t('tourTaskTechnicianView.status')}
                            cellRender={(cellInfo) => renderStatus(cellInfo)}
                            minWidth={200}
                            hidingPriority={8}
                    >
                        <Lookup dataSource={getFilteredTourTaskStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="comment"
                            caption={t('tourTaskTechnicianView.comment')}
                            dataType="string"
                            hidingPriority={4}/>
                    <Column dataField="accomplishedAt" caption={t('tourTaskTechnicianView.accomplishedAt')}
                            dataType="datetime"
                            visible={false}
                            hidingPriority={5}/>
                    <Column dataField="accomplishedUserId"
                            caption={t('tourTaskTechnicianView.accomplishedUserId')}
                            dataType={"string"}
                            visible={false}
                            hidingPriority={9}>
                        <Lookup dataSource={getFilteredUsers} valueExpr={"id"} displayExpr={"lastName"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="images"
                            caption={t('tourTaskTechnicianView.images')}
                            editCellRender={(cell) => <ImagesItem cell={cell}/>}
                            visible={false}
                    />
                    <Column dataField="type"
                            caption={t('tourTaskTechnicianView.type')}
                            allowEditing={false}
                            visible={false}>
                        <Lookup dataSource={getFilteredTourTaskType} valueExpr={"id"} displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column type="buttons">
                        <DataGridButton name="edit" />
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default TourTaskTechnicianView;
