import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting, EmptyItem, Button as DataGridButton
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {
    Item, RequiredRule, AsyncRule, Label,
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import "devextreme-react/select-box";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore, Hub } from "aws-amplify";
import { Period, UserRole} from "../../models";
import {useTranslation} from "react-i18next";
import {periodsStore, handleSubscriptionEvent} from '../../data/periodsStore';
import { CheckBox } from 'devextreme-react/check-box';
import AddPeriodFormPopup from '../AddPeriodFormPopup/AddPeriodFormPopup';
import {navigate} from "hookrouter";
import PageHeader from "../PageHeader/PageHeader";

function PeriodManagementView({periodType}) {
    const translationPrefix = periodType === "DEVICE_CHANGE" ? "changePeriodManagementView" : "readingPeriodManagementView";

    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [filter, setFilter] = useState(['type','=', periodType]);
    const datagrid = useRef(null);

    const customAddButtonOptions = {
        icon: 'add',
        onClick() {
            setShowAddForm(true);
        }
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addPeriod = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Perioden anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editPeriod = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Perioden bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removePeriod = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Perioden löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
        event.data.temporaryPlacement=false;
    }

    const forwardToToursManagementView = (event) => {
        const periodId = event.row.data.id;
        const path = window.location.pathname.split('/');
        navigate(`/${path[1]}/${periodId}/tours`);
    }

    useEffect(() => {
        /*const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                datagrid?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);*/
        const subscription = DataStore.observe(Period).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            //Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    useEffect(() => {
        setFilter(['type','=', periodType]);
    }, [periodType]);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="PeriodManagementView">
                <PageHeader headerText={t(`${translationPrefix}.header`)} />
                <DataGrid
                    ref={ref => datagrid.current = ref}
                    dataSource={periodsStore}
                    defaultFilterValue={['type','=', periodType]}
                    filterValue={filter}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: true
                    }}
                    id="periods"
                    //onRowClick={e => console.log(e)}
                    onRowInserting={e => addPeriod(e)}
                    onRowUpdating={e => editPeriod(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removePeriod(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem
                            widget="dxButton"
                            options={customAddButtonOptions}
                            location={"after"}
                            locateInMenu={"auto"}
                        />
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                    <Editing
                        mode={"popup"}
                        useIcons={true}
                        allowAdding={false}
                        allowUpdating={allowEditing}
                        allowDeleting={allowDeleting}
                    >
                        <Popup title={!editing ? t(`${translationPrefix}.popupTitle1`) : t(`${translationPrefix}.popupTitle2`)}
                               showTitle={true}
                               width="auto"
                            //minWidth={"50vw"}
                               maxHeight={"100vh"}
                               height="auto"/>
                        <Form colCount={2} showValidationSummary={true}>
                            <Item dataField="year" colSpan={1}>
                                <RequiredRule message={t(`${translationPrefix}.yearRequiredRuleMsg`)}/>
                            </Item>
                            <Item dataField="comment"
                                  colSpan={1}
                            />
                        </Form>
                    </Editing>
                    <Column dataField="type" caption={t(`${translationPrefix}.type`)} dataType={"string"} visible={false} />
                    <Column dataField="year"
                            caption={t(`${translationPrefix}.year`)}
                            dataType={"number"}
                            defaultSortOrder={"desc"}
                            hidingPriority={10}/>
                    <Column dataField="comment" caption={t(`${translationPrefix}.comment`)} dataType={"string"} hidingPriority={7}/>
                    {/*<Column dataField="devices" caption={t(`${translationPrefix}.devices`)}
                            dataType={"object"}
                            cellRender={renderDevicesCell}
                            hidingPriority={9}/>*/}
                    {/*<Column dataField="tours" caption={t(`${translationPrefix}.tours`)} dataType={"object"} hidingPriority={8}/>*/}
                    <Column dataField="createdAt" caption={t(`${translationPrefix}.createdAt`)} dataType={"datetime"} hidingPriority={6}/>
                    <Column dataField="updatedAt" caption={t(`${translationPrefix}.updatedAt`)} dataType={"datetime"} hidingPriority={5}/>
                    <Column type="buttons">
                        <DataGridButton hint={t(`${translationPrefix}.tourBtnHint`)} icon="fa-solid fa-route" visible={true} disabled={false} onClick={(e) => forwardToToursManagementView(e)} />
                        <DataGridButton name="edit" />
                        <DataGridButton name="delete" />
                    </Column>
                </DataGrid>
                {showAddForm ?
                    <AddPeriodFormPopup
                        onHiding={() => setShowAddForm(false)}
                        periodType={periodType}
                    />
                    :
                    null
                }
            </div>
        );
    } else {
        return null;
    }
}

export default PeriodManagementView;
