import './S3FilesList.css';
import React from "react";
import PropTypes from 'prop-types';
import {Amplify, Storage} from 'aws-amplify';
import awsconfig from '../aws-exports';
import deMessages from "devextreme/localization/messages/de.json";
import {loadMessages, locale} from "devextreme/localization";
import DataGrid, {
    Column,
    HeaderFilter,
    LoadPanel,
    Pager,
    Paging,
    Button as DataGridButton
} from "devextreme-react/data-grid";
import {Scrolling} from "devextreme-react/tree-list";
import {useTranslation} from "react-i18next";

Amplify.configure(awsconfig);

function S3FilesList({files, bucketName, allowedFileExtensions, prefix}) {
    const [t] = useTranslation();
    loadMessages(deMessages);
    locale(navigator.language);

    const downloadBlob = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    const onDownloadClick = async (event) => {
        const fileKey = event.row.data.key;
        const result = await Storage.get(fileKey, {
            bucket: bucketName,
            level: "protected",
            download: true
            });
        downloadBlob(result.Body, fileKey);
    }

    // formats the displayed filename to hide path and -<timstamp> suffix of the filename
    const formatFilename = (filename) => {
        return filename.value.replace(/^.*[\\/]/, '').replace(/-\d+\./, '.');
    }

    return (
        <div className={"S3FilesList"}>
            <DataGrid
                dataSource={files}
                showBorders={true}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                wordWrapEnabled={true}
                allowColumnResizing={true}
                showColumnLines={false}
                showRowLines={false}
                rowAlternationEnabled={true}
                keyExpr="key"
                id="s3fileslist"
            >
                <LoadPanel enabled/>
                <Scrolling
                    mode="standard" />
                <Paging
                    enabled={true}
                    defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 25, 50, 'all']}
                    showInfo={true}/>
                <HeaderFilter visible={true}/>
                <Column dataField="lastModified"
                        caption={t('s3fileslist.columnCaption1')}
                        dataType={'date'}
                        format={'dd.MM.yyyy - HH:mm:ss'}
                        defaultSortOrder="desc"
                />
                <Column dataField="key"
                        dataType={"string"}
                        caption={t('s3fileslist.columnCaption2')}
                        customizeText={formatFilename}/>
                <Column type="buttons"
                        caption={t('s3fileslist.columnCaption3')}>
                    <DataGridButton
                        hint={t('s3fileslist.columnCaption3')}
                        icon="download"
                        visible={true}
                        disabled={false}
                        onClick={onDownloadClick}
                    />
                </Column>
            </DataGrid>
        </div>
    );
}

S3FilesList.propTypes = {
    files: PropTypes.array.isRequired,
    bucketName:PropTypes.string.isRequired,
    allowedFileExtensions: PropTypes.array.isRequired,
    prefix: PropTypes.string
}

export default S3FilesList;