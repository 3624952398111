import '../../App.css';
import React, {useContext, Suspense} from "react";
import AuthContext from "../../contexts/AuthContext";
import {useTranslation} from "react-i18next";
import FallbackLoader from "../FallbackLoader";
import {UserRole} from "../../models";
import S3FilesComponent from "../S3FilesComponent";
import PageHeader from "../PageHeader/PageHeader";

//const S3FilesComponent = lazy(() =>import("../S3FilesComponent"));

const bucketName = process.env.REACT_APP_ENV + "-smp-data";
const destFolder = "import/customer-data/";

function CitizenImport() {
    const [t] = useTranslation();
    const userContext = useContext(AuthContext);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="CitizenImport">
                <PageHeader headerText={t('citizenimport.header')} />
                <Suspense fallback={
                    <FallbackLoader />
                }>
                    <S3FilesComponent
                        uploadBtnText={t('citizenimport.uploadBtnText')}
                        bucketName={bucketName}
                        destFolder={destFolder}
                        allowedFileExtensions={['.xml']}
                    />
                </Suspense>
            </div>
        );
    } else {
        return null;
    }
}

export default CitizenImport;
