import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {
    Item, RequiredRule, AsyncRule, Label, GroupItem
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import "devextreme-react/select-box";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore, Hub } from "aws-amplify";
import { StockLocation, StockLocationStatus, UserRole} from "../../models";
import {useTranslation} from "react-i18next";
import {stockLocationsStore, handleSubscriptionEvent} from '../../data/stockLocationsStore';
import PageHeader from "../PageHeader/PageHeader";
import ImagesItem from "../ImagesItem/ImagesItem";

const stockLocationStatus = [{id: StockLocationStatus.ACTIVE, name:'aktiv'},{id: StockLocationStatus.INACTIVE, name:'inaktiv'}];

function StockLocationManagementView() {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState([]);
    const datagrid = useRef(null);

    const getFilteredStockLocationStatus = (options) => {
        let filter = null;
        return {
            store: stockLocationStatus,
            filter:filter
        };
    }

    const renderAddressCell = (data) => {
        if (data.value) {
            return (
                <div className={"stockLocation address"}>
                    <span>{data.value.street} {data.value.streetNr}</span>
                    <span>{data.value.zipCode} {data.value.city}</span>
                    <span>{data.value.country}</span>
                </div>
            )}
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addStockLocation = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Lagerorte anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editStockLocation = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Lagerorte bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeStockLocation = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Lagerorte löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
    }

    useEffect(() => {
        const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            console.log("DataStore event", event);
            if (event === 'ready') {
                datagrid?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);
        // apparently this is important
        const subscription = DataStore.observe(StockLocation).subscribe(handleSubscriptionEvent);
        /*DataStore.start()
            .catch(() => {
                DataStore.clear().then(() => {
                    DataStore.start();
                    subscription = DataStore.observe(User).subscribe(handleSubscriptionEvent);
                });
            })
            .then(() => {
                subscription = DataStore.observe(User).subscribe(handleSubscriptionEvent);
            });*/
        return () => {
            subscription.unsubscribe();
            Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="StockLocationManagementView">
                <PageHeader headerText={t('stockLocationManagementView.header')} />
                <DataGrid
                    ref={ref => datagrid.current = ref}
                    dataSource={stockLocationsStore}
                    defaultFilterValue={[]}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    id="stockLocations"
                    onRowInserting={e => addStockLocation(e)}
                    onRowUpdating={e => editStockLocation(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeStockLocation(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('stockLocationManagementView.popupTitle1') : t('stockLocationManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={2} showValidationSummary={true}>
                                <GroupItem caption={t('stockLocationManagementView.groupItem1')}>
                                    <Item dataField="name" colSpan={1}>
                                        <RequiredRule/>
                                    </Item>
                                    <Item dataField="status" colSpan={1}/>
                                    <Item dataField="comment" colSpan={1}/>
                                    <Item
                                        dataField="images"
                                        colSpan={1}/>
                                </GroupItem>
                                <GroupItem caption={t('stockLocationManagementView.groupItem2')}>
                                    <Item dataField={"address.street"} colSpan={1}/>
                                    <Item dataField={"address.streetNr"} colSpan={1}/>
                                    <Item dataField={"address.zipCode"} colSpan={1}/>
                                    <Item dataField={"address.city"} colSpan={1}/>
                                    <Item dataField={"address.country"} colSpan={1}/>
                                </GroupItem>
                            </Form>
                        </Editing> : null}
                    <Column dataField="name"
                            caption={t('stockLocationManagementView.name')}
                            defaultSortOrder="asc"
                            hidingPriority={10}/>
                    <Column dataField="status" caption={t('stockLocationManagementView.status')} hidingPriority={8}>
                        <Lookup dataSource={getFilteredStockLocationStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="address"
                            caption={t('stockLocationManagementView.address')}
                            cellRender={renderAddressCell}
                            hidingPriority={6}
                            allowSorting={false}
                            allowFiltering={false}/>
                    <Column dataField="comment" caption={t('stockLocationManagementView.comment')} hidingPriority={10}/>
                    <Column dataField="address.street"
                            caption={t('stockLocationManagementView.street')}
                            visible={false}/>
                    <Column dataField="address.streetNr"
                            caption={t('stockLocationManagementView.streetNr')}
                            visible={false}/>
                    <Column dataField="address.zipCode"
                            caption={t('stockLocationManagementView.zipCode')}
                            visible={false}/>
                    <Column dataField="address.city"
                            caption={t('stockLocationManagementView.city')}
                            visible={false}/>
                    <Column dataField="address.country"
                            caption={t('stockLocationManagementView.country')}
                            visible={false}/>
                    <Column dataField="images"
                            caption={t('stockLocationManagementView.images')}
                            hidingPriority={9}
                            editCellRender={(cell) => <ImagesItem cell={cell}/>}
                            visible={false}
                    />
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default StockLocationManagementView;
