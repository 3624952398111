/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://fvulsdhlcrf6pfdue4xxjqrh7q.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:7350b84a-ac05-42fd-9789-7757ea4181a7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_eV1WWsYiI",
    "aws_user_pools_web_client_id": "21r8d7cpjfgdp2o23ui2kmd9jk",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "usermanagementapp77d9772bf1d342f3ae4f49e33ca740122506-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
