export const NavbarMenuItems = [
    {
        "name": "Home",
        "path": "https://www.wolfsberger-stadtwerke.at/",
        "link": true
    }, {
        "name": "Über uns",
        "path": "https://www.wolfsberger-stadtwerke.at/das-unternehmen",
        "link": true
    }, {
        "name": "Kontakt",
        "path": "https://www.wolfsberger-stadtwerke.at/kontakt",
        "link": true
    }];

export const AdminMenuItems = [
    {
        "text": "Benutzer",
        "path": "/users",
        "link": false,
        "icon": 'fa-solid fa-user-gear'
    }, {
        "text": "Zähler",
        "link": false,
        "icon": "fa-solid fa-droplet",
        "items": [/*{
            "text": "Übersicht",
            "path": "/watermeters",
            "link": false,
            "icon": 'fa-solid fa-table-columns'
        },*/{
            "text": "Abgabestellen",
            "path": "/meterpoints",
            "link": false,
            "icon": 'fa-solid fa-table-columns'
        }, {
            "text": "Import",
            "path": "/watermeter-import",
            "link": false,
            "icon": 'fa-solid fa-cloud-arrow-up'
        }, {
            "text": "Tausch",
            "path": "/device-swap",
            "link": false,
            "icon": "fa-solid fa-repeat"
        }, /*{
            "text": "Ablesung",
            "path": "/device-reading",
            "link": false,
            "icon": 'fa-solid fa-gauge-high'
        },*/ {
            "text": "Lager",
            "path": "/warehouse",
            "link": false,
            "icon": 'fa-solid fa-warehouse'
        }]
    }, {
        "text": "Bürger",
        "link": false,
        "icon": 'fa-solid fa-users-gear',
        "items": [{
            "text": "Übersicht",
            "path": "/citizens",
            "link": false,
            "icon": 'fa-solid fa-table-columns'
        }, {
            "text": "Import",
            "path": "/citizen-import",
            "link": false,
            "icon": 'fa-solid fa-cloud-arrow-up'
        }]
    }, {
        "text": "Dashboard",
        "path": "/dashboard",
        "link": false,
        "icon": 'fa-solid fa-chart-line'
    }, {
        "text": "Verrechnung Export",
        "path": "/billing-export",
        "link": false,
        "icon": 'fa-solid fa-euro-sign'
    }, {
        "text": "Einstellungen",
        "path": "/settings",
        "link": false,
        "icon": 'fa-solid fa-sliders'
    }, {
        "text": "Hilfe",
        "path": "/help",
        "link": false,
        "icon": 'fa-solid fa-question'
    }];
export const TechnicianMenuItems = [
    {
        "text": "Zählertausch",
        "path": "/tech-dev-swap",
        "link": false,
        "icon": "fa-solid fa-repeat"
    },
    {
        "text": "Dashboard",
        "path": "/dashboard",
        "link": false,
        "icon": 'fa-solid fa-chart-line'
    }];
export const DefaultMenuItems = [
    {
        "text": "Dashboard",
        "path": "/dashboard",
        "link": false,
        "icon": 'fa-solid fa-chart-line'
    }];