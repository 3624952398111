import CustomStore from 'devextreme/data/custom_store';
import { DataStore } from "aws-amplify";
import { User, UserRole} from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering} from "../common/customDataStore";

const mapUserData = async (user) => {
    const {
        id,
        firstName,
        lastName,
        email,
        role,
        status,
    } = user;
    return {
        id,
        firstName,
        lastName,
        email,
        role,
        status
    };
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];
    // exclude always SuperAdmins
    filtersList.push(c.role.ne(UserRole.SUPER_ADMIN));
    // to filter by role
    if (filterOps?.role) {
        filtersList.push(c.role.eq(filterOps.role));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    return c.and(c => mapFilters(c,filterOps));
}

export const usersStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let users = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            users = await DataStore.query(User, (user) => user.role.ne(UserRole.SUPER_ADMIN));
            // map users
            users = await Promise.all(users.map((sensor) => mapUserData(sensor)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            users = await DataStore.query(User,
                c => applySelectedFiltering(c,filter));
            users = await Promise.all(users.map((ret) => mapUserData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            users = await DataStore.query(User,
                (user) => applyDataStoreFiltering(user,filter), {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map users
            users = await Promise.all(users.map((user) => mapUserData(user)));
        }
        return {
            data: users
        }
    },
    remove: (key) => {
        return DataStore.query(User, key)
            .then((original) => {
                    DataStore.delete(original)
                        .then(() =>
                            notify(`Benutzer ${original.email} wurde erfolgreich gelöscht`, "success", 3000)
                        ).catch((error) => {
                            notify(`Benutzer ${original.email} wurde nicht gelöscht`, "error", 3000)
                            throw Error(error);
                        })
                }
            )
            .catch((error) => {
                notify(`Kein Benutzer mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: (data) => {
        const id = uuidv4();
        return DataStore.save(
            new User({
                id: id,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                status: data.status,
                role: data.role,
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN],
                owner: id
            })
        ).then(() => notify(`Benutzer wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`Benutzer wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        return DataStore.query(User, key)
            .then((original) => DataStore.save(
                User.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        updated[key] = value;
                    }
                })).then(() => notify(`Benutzer wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Benutzer wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein Benutzer mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(User, key)
            .then(async (original) => await mapUserData(original))
            .catch((error) => {
                notify(`Kein Benutzer mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = {
            id: elem.id,
            firstName: elem.firstName,
            lastName: elem.lastName,
            email: elem.email,
            role: elem.role,
            status: elem.status,
        };
        switch(data.opType) {
            case "UPDATE":
                usersStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                usersStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                usersStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}