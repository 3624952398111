import './S3FileUploader.css';
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Auth, Storage} from 'aws-amplify';
import FileUploader from "devextreme-react/file-uploader";
import deMessages from "devextreme/localization/messages/de.json";
import {loadMessages, locale} from "devextreme/localization";
import notify from "devextreme/ui/notify";
import {useTranslation} from "react-i18next";
import { SFNClient, ListExecutionsCommand, GetActivityTaskCommand, SendTaskSuccessCommand } from "@aws-sdk/client-sfn";
import awsconfig from '../aws-exports';
import { custom } from 'devextreme/ui/dialog';

const csvStateMachinArn = process.env.REACT_APP_CSV_STATE_MACHINE_ARN;
const csvStateMachineApproveActivityArn = process.env.REACT_APP_CSV_STATE_MACHINE_APPROVE_ACTIVITY_ARN;

const credentials = await Auth.currentCredentials()

const client = new SFNClient({region: awsconfig.aws_project_region, credentials: credentials});

export const confirmImportDialog = (presentAmount, newAmount) => {
    let dialog = custom({
        title: "Import Bestätigung",
        messageHtml: `${presentAmount} Sensoren sind bereits vorhanden, ${newAmount} neue Sensoren werden hinzugefügt.`,
        buttons: [
            {
                stylingMode: 'outlined',
                hoverStateEnabled: true,
                activeStateEnabled: false,
                focusStateEnabled: true,
                text: "Importieren",
                onClick: () => true,
            },
            {
                stylingMode: 'outlined',
                hoverStateEnabled: true,
                activeStateEnabled: false,
                focusStateEnabled: true,
                text: "Abbrechen",
                onClick: () => false,
            },
        ],
    });
    return dialog.show();
};

/**
 * Lists all executions of the state machine csvStateMachinArn
 * @returns {{
  executions: [
    {
      executionArn: "STRING_VALUE",
      stateMachineArn: "STRING_VALUE",
      name: "STRING_VALUE",
      status: "RUNNING" || "SUCCEEDED" || "FAILED" || "TIMED_OUT" || "ABORTED",
      startDate: new Date("TIMESTAMP"),
      stopDate: new Date("TIMESTAMP"),
      mapRunArn: "STRING_VALUE",
      itemCount: Number("int"),
      stateMachineVersionArn: "STRING_VALUE",
      stateMachineAliasArn: "STRING_VALUE",
    },
  ],
  nextToken: "STRING_VALUE",
}}
 *
 */
const listExecutions = async () => {
    const input = { // ListExecutionsInput
        stateMachineArn: csvStateMachinArn,
        //statusFilter: "RUNNING" || "SUCCEEDED" || "FAILED" || "TIMED_OUT" || "ABORTED",
        maxResults: 1,
        nextToken: null,
        //mapRunArn: "STRING_VALUE",
    };
    return client.send(new ListExecutionsCommand(input));
}

const isUploadEnabled = () => {
    const latestExec = listExecutions().then((exec) => exec.executions[0]);
    return (!latestExec || (latestExec?.status === "SUCCEEDED"))
}

/**
 * Retrieve a task (with the specified activity ARN) which has been scheduled for execution by a running state machine
 * @returns {{
   taskToken: "STRING_VALUE",
   input: "STRING_VALUE",
 }}
 */
const getActivityTask = async () => {
    const input = { // GetActivityTaskInput
        activityArn: csvStateMachineApproveActivityArn, // required
    };
    return client.send(new GetActivityTaskCommand(input));
}

/**
 * report that the task identified by the taskToken completed successfully
 * @param taskToken {String} the token that represents this task.
 * @param approvedValue {Boolean} represents the user choice
 * @returns {Promise<*>}
 */
const sendActivityTask = async (taskToken, approvedValue) => {
    const input = { // SendTaskSuccessInput
        taskToken: taskToken, // required
        output: JSON.stringify({approved:approvedValue}), // required
    };
    console.log(input);
    return client.send(new SendTaskSuccessCommand(input));
}

function S3FileUploader({uploadBtnText, bucketName, destFolder, allowedFileExtensions, callback, type="XML"}) {
    const [t] = useTranslation();
    const [uploadEnabled, setUploadEnabled] = useState(false);
    loadMessages(deMessages);
    locale(navigator.language);

    const uploadToS3Bucket = async (file, progressCallback) => {
        const fullFileName = file.name;
        const dotIndex = fullFileName.lastIndexOf('.');
        const filename = fullFileName.slice(0,dotIndex).replace(/[. *+?^${}()|[\]\\]/g, "-");
        const extension = fullFileName.slice(dotIndex);
        const timestamp = new Date().getTime();
        const destFile = `${destFolder}${filename}-${timestamp}${extension}`;
        try {
            // upload
            await Storage.put(destFile, file, {
                    bucket: bucketName,
                    level: "protected",
                    progressCallback(progress) {
                        //console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        progressCallback(progress.loaded);
                    }
                }
            );
            // get activity task
            let {taskToken, input} = await getActivityTask();
            input = JSON.parse(input);
            // confirm dialogue
            return confirmImportDialog(input.presentAmount, input.absentAmount).then(async (dialogResult) => {
                let approved = false;
                if (dialogResult) {// send task success true
                    approved = true;
                }
                await sendActivityTask(taskToken, approved)

                if (callback) {
                    callback();
                }

                if (approved === true) {
                    notify(t('s3fileuploader.msg_success1'), "success", 3000);
                }
            });
        } catch(err) {
            notify(t('s3fileuploader.msg_error1'), "error", 3000);
            console.error(err);
        }
    }

    const pollExecutions = () => {
        if (type === "CSV") {
            setUploadEnabled(isUploadEnabled())
        } else {
            setUploadEnabled(true);
        }
    }

    useEffect(() => {
        const intervalCall = setInterval(() => {
            pollExecutions();
        }, 10000); // poll interval is 10sec
        pollExecutions();
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [])

    return (
        <div className={"S3FileUploader"}>
            <FileUploader
                id="file-uploader"
                name={"uploader"}
                multiple={false}
                allowedFileExtensions={allowedFileExtensions}
                focusStateEnabled={false}
                uploadMode="useButtons"
                showFileList={true}
                uploadFile={uploadToS3Bucket}
                readOnly={uploadEnabled}
            />
        </div>
    );
}

S3FileUploader.propTypes = {
    uploadBtnText: PropTypes.string.isRequired,
    bucketName:PropTypes.string.isRequired,
    destFolder: PropTypes.string.isRequired,
    allowedFileExtensions: PropTypes.array.isRequired,
    callback: PropTypes.func
}

export default S3FileUploader;