import './S3FilesList.css';
import React, {Suspense, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Storage} from 'aws-amplify';
import FallbackLoader from "./FallbackLoader";
import S3FileUploader from "./S3FileUploader";
import S3FilesList from "./S3FilesList";

//const S3FileUploader = lazy(() =>import("./S3FileUploader"));
//const S3FilesList = lazy(() =>import("./S3FilesList"));

function S3FilesComponent({uploadBtnText, bucketName, destFolder, allowedFileExtensions, prefix, type="XML"}) {
    const [files, setFiles] = useState([]);

    const onFileUploaded = () => {
        listFiles();
    }

    const listFiles = () => {
        Storage.list(prefix ? prefix : '', {
            bucket: bucketName, level: "protected"}) // for listing ALL files without prefix, pass '' instead
            .then((result) => setFiles(
                result.filter(
                    (file) => {
                        return allowedFileExtensions.some(ext => file.key.endsWith(ext))
                    })
            ))
            .catch((err) => console.error(err));
    }

    useEffect(() => {
        listFiles();
    }, []);

    return (
        <div className={"S3FilesComponent"}>
            <Suspense fallback={
                <FallbackLoader />
            }>
                <S3FileUploader
                    uploadBtnText={uploadBtnText}
                    bucketName={bucketName}
                    destFolder={destFolder}
                    allowedFileExtensions={allowedFileExtensions}
                    callback={onFileUploaded}
                    type={type}
                />
                <S3FilesList
                    files={files}
                    bucketName={bucketName}
                    prefix={destFolder}
                    allowedFileExtensions={allowedFileExtensions}
                />
            </Suspense>
        </div>
    );
}

S3FilesComponent.propTypes = {
    uploadBtnText: PropTypes.string.isRequired,
    bucketName:PropTypes.string.isRequired,
    destFolder:PropTypes.string.isRequired,
    allowedFileExtensions: PropTypes.array.isRequired,
    prefix: PropTypes.string,
    type: PropTypes.string // XML/CSV; to distinct XML and CSV uploaders as the behavior might differ
}

export default S3FilesComponent;