import CustomStore from 'devextreme/data/custom_store';
import { DataStore, Predicates } from "aws-amplify";
import { Object, UserRole } from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, uploadImage, dateToAWSDate, dateToAWSDateTime} from "../common/customDataStore";

export const mapObjectData = async (object) => {
    let {
        id,
        objectId,
        name,
        customerId,
        customer,
        meteringPoints,
        tbId,
        geoLocation,
        address,
        images,
        createdAt,
        updatedAt,
    } = object;
    if (images) {
        images = await images.toArray();
        //images = await Promise.all(images.map((t) => mapImageData(t)));
    }
    return {
        id,
        objectId,
        name,
        customerId,
        customer,
        meteringPoints,
        tbId,
        geoLocation,
        address,
        images,
        createdAt,
        updatedAt
    };
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];

    if (filterOps?.objectId) {
        filtersList.push(c.objectId.eq(filterOps.objectId));
    }
    if (filterOps?.customerId) {
        filtersList.push(c.customerId.eq(filterOps.customerId));
    }
    if (filterOps?.name) {
        filtersList.push(c.name.eq(filterOps.name));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    return c.and(c => mapFilters(c,filterOps));
}

export const objectsStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let objects = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            objects = await DataStore.query(Object, Predicates.All);
            // map objects
            objects = await Promise.all(objects.map((object) => mapObjectData(object)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            objects = await DataStore.query(Object,
                c => applySelectedFiltering(c,filter));
            objects = await Promise.all(objects.map((ret) => mapObjectData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            objects = await DataStore.query(Object,
                c => applyDataStoreFiltering(c,filter), {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map objects
            objects = await Promise.all(objects.map((object) => mapObjectData(object)));
        }
        //console.log(objects)
        return {
            data: objects
        }
    },
    insert: (data) => {
        const id = uuidv4();
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from Object: ${data.objectId}`,
                        id);
                }
            })
        }
        return DataStore.save(
            new Object({
                id: id,
                objectId: data.objectId,
                name: data.name,
                customerId: data.customerId,
                tbId: data.tbId,
                //geoLocation
                //address
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN]

            })
        ).then(() => notify(`Object wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`Object wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from Object: ${data.objectId}`,
                        key);
                }
            })
        }
        return DataStore.query(Object, key)
            .then((original) => DataStore.save(
                Object.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        updated[key] = value;
                    }
                })).then(() => notify(`Object ${original.objectId}  wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Object ${original.objectId}  wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein Object mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(Object, key)
            .then(async (original) => await mapObjectData(original))
            .catch((error) => {
                notify(`Kein Object mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapObjectData(elem);
        switch(data.opType) {
            case "UPDATE":
                objectsStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                objectsStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                objectsStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}