import './QrCodeScanner.css';
import React, {useContext, useEffect} from "react";
//import { Html5QrcodeScanner} from 'html5-qrcode';
import {Html5Qrcode} from "html5-qrcode"

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = ({fps, qrbox, aspectRatio, disableFlip}) => {
    let config = {};
    config.fps = fps ? fps : 10;
    config.qrbox = qrbox ? qrbox : {width: Math.max(50, window.innerWidth/1.2), height: Math.max(50, window.innerHeight/1.2)};
    //config.aspectRatio = 1;
    if (aspectRatio) {
        config.aspectRatio = aspectRatio;
    }
    config.disableFlip = disableFlip ? disableFlip : false;
    config.rememberLastUsedCamera = true;
    return config;
};

function QrCodeScanner({qrCodeSuccessCallback, qrCodeErrorCallback, fps, qrbox, aspectRatio, disableFlip, verbose}) {

    useEffect(() => {
        // when component mounts
        const config = createConfig({fps, qrbox, aspectRatio, disableFlip});
        const verboseOn = (verbose === true);

        //const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verboseOn);
        //html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

        // cleanup function when component will unmount
        /*return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };*/
        const html5QrCode = new Html5Qrcode(qrcodeRegionId);
        // If you want to prefer back camera
        html5QrCode.start({facingMode: "environment"}, config, qrCodeSuccessCallback);

        return () => {
            html5QrCode.stop().catch(error => {
               console.error("Failed to clear html5QrcodeScanner. ", error);
           });
       };
    }, []);

    return (
        <div className={"QrCodeScanner"}>
            <div id={qrcodeRegionId}></div>
        </div>
    );
}

export default QrCodeScanner;
