import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, LoadPanel, Lookup, Toolbar, Item as TItem, Column, ColumnChooser, Sorting, Button as DataGridButton
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import AuthContext from "../../contexts/AuthContext";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore } from "aws-amplify";
import { Tour, UserRole } from "../../models";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";
import {toursStore, handleSubscriptionEvent} from "../../data/toursStore";
import { TourTaskStatus } from "../../models";
import {usersStore} from "../../data/usersStore";
import PageHeader from "../PageHeader/PageHeader";

const renderTourTasksStatusSummary = (tasks) => {
    let further_action = 0;
    let no_customer = 0;
    let done = 0;
    let total = 0;

    if (tasks) {
        tasks.forEach((t) => {
            switch(t.status) {
                case TourTaskStatus.DONE: done++; break;
                case TourTaskStatus.NO_CUSTOMER: no_customer++; break;
                case TourTaskStatus.FURTHER_ACTION: further_action++; break;
            }
            total++;
        })
    }
    let proccessed = further_action + no_customer + done;
    return (
            <div className="TourTasksStatusSummary">
                <div className="done">
                    <i className="fa-solid fa-circle-check"></i> {done}
                </div>
                <div className="furtherAction">
                    <i className="fa-solid fa-triangle-exclamation"></i> {further_action}
                </div>
                <div className="noCustomer">
                    <i className="fa-solid fa-user-slash"></i> {no_customer}
                </div>
                <div className="progress">
                    ({proccessed}/{total})
                </div>
            </div>
    );
};

const renderTourDetails = (cellInfo) => {
    return (
        cellInfo.data ?
            <div className="TourDescription">
                <div className="buchNr">
                    {cellInfo.data.buchNr ? `BuchNr ${cellInfo.data.buchNr}` : "keine Daten"}
                </div>
                {renderTourTasksStatusSummary(cellInfo.data.tasks)}
            </div>
            :
            <div className="TourDescription">
                <p>{"keine Daten"}</p>
            </div>
    );
};

function TourTechnicianView() {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const tourStatus = t('tourStatus',{ returnObjects: true });
    const filteringValues = t('tourTechnicianStatusFilteringValues',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    // userContext?.auth?.username
    const [assignedToUserIdFilter, setAssignedToUserIdFilter] = useState(['assignedToUserId', "=", userContext?.userInfoFromDB?.username]);
    const [statusFilter, setStatusFilter] = useState("ASSIGNED_TO_TECHNICIAN");
    const dataGridRef = useRef(null);

    const getFilteredTourStatus = (options) => {
        let filter = null;
        return {
            store: tourStatus,
            filter:filter
        };
    }

    const getFilteredUsers = (options) => {
        let filter = [['role', '=', 'Technician']];
        return {
            store: usersStore,
            filter:filter
        };
    }

    const forwardToTasksManagementView = (event) => {
        const periodId = event.data.periodId;
        const tourId = event.data.id;
        const path = window.location.pathname.split('/');
        navigate(`/${path[1]}/${periodId}/${tourId}/tasks`);
    }

    useEffect(() => {
        /*const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                dataGridRef?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);*/
        const subscription = DataStore.observe(Tour).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            //Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    useEffect(() => {
        setAssignedToUserIdFilter([['assignedToUserId', "=", userContext?.userInfoFromDB?.id]]);
    }, [userContext]);

    if (userContext.userInfoFromDB && [UserRole.TECHNICIAN, UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="TourTechnicianView">
                <PageHeader
                    headerText={t('tourTechnicianView.header')}
                />
                <DataGrid
                    ref={ref => dataGridRef.current = ref}
                    dataSource={toursStore}
                    defaultFilterValue={[assignedToUserIdFilter, "and",statusFilter]}
                    filterValue={[assignedToUserIdFilter, "and", statusFilter ? ["status", "=", statusFilter] : []]}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={false}
                    showBorders={false}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    onRowClick={(e) => {
                        forwardToTasksManagementView(e);
                    }}
                    id="tours"
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={false} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem location={"before"} locateInMenu={"auto"}>
                            <SelectBox
                                width="auto"
                                items={filteringValues}
                                displayExpr="text"
                                valueExpr="value"
                                value={statusFilter}
                                onValueChanged={(event) => {setStatusFilter(event.value)}} />
                        </TItem>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                    <Column dataField="buchNr"
                            caption={t('tourTechnicianView.tour')}
                            cellRender={(cellInfo) => renderTourDetails(cellInfo)}
                            allowSorting={true}
                            allowEditing={false}
                            minWidth={200}
                            alignment={"left"}
                            defaultSortOrder="asc"
                            hidingPriority={11}
                    />
                    <Column dataField="status" caption={t('tourTechnicianView.status')}
                            dataType={"string"}
                            hidingPriority={8}
                            visible={false}
                    >
                        <Lookup dataSource={getFilteredTourStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="date"
                            caption={t('tourTechnicianView.date')}
                            dataType="date"
                            hidingPriority={5}/>
                    <Column dataField="comment"
                            caption={t('tourTechnicianView.comment')}
                            dataType="string"
                            hidingPriority={4}/>
                    <Column dataField="assignedToUserId"
                            caption={t('tourTechnicianView.assignedToUserId')}
                            dataType={"string"}
                            visible={false}
                            hidingPriority={9}>
                        <Lookup dataSource={getFilteredUsers} valueExpr={"id"} displayExpr={"lastName"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="accomplishedAt" caption={t('tourTechnicianView.accomplishedAt')}
                            dataType="datetime"
                            hidingPriority={6}/>
                    <Column dataField="periodId" caption={t('tourTechnicianView.periodId')} dataType={"string"} visible={false} />
                    {/*<Column type="buttons">
                        <DataGridButton hint={t('tourTechnicianView.tourTaskBtnHint')} icon="fa-solid fa-briefcase" visible={true} disabled={false} onClick={(e) => forwardToTasksManagementView(e)} />
                    </Column>*/}
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default TourTechnicianView;
