import CustomStore from 'devextreme/data/custom_store';
import { DataStore } from "aws-amplify";
import { MeteringPoint, MeteringPointStatus, UserRole } from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, uploadImage, dateToAWSDate, dateToAWSDateTime} from "../common/customDataStore";
import {mapObjectData} from "./objectsStore";
import {mapCustomerData} from "./customersStore";

export const mapMeteringPointData = async (meteringPoint) => {
    let {
        id,
        edvObjAbgId,
    abgabeNr,
    abgabeLfdNr,
    betriebsNr,
    bauJahr,
    letztesEichJahr,
    eichIntervall,
    anzahlStellen,
    fabrikat,
    einbauDatum,
    wasserGenossenschaft,
    versorgungsKreis,
    anschlussDatum,
    buchNr,
    buchNrLfd,
    durchFlussGroesse,
    zaehlerArt,
    anfangsStand,
    anfangsStandDatum,
    endStand,
    endStandDatum,
    webAblesung,
    zlrVerbrLetztPer,
    deviceId,
    //device,
    objectId,
    object,
    customerId,
    customer,
    images,
    periods,
    tbId,
    createdAt,
    updatedAt,
    } = meteringPoint;
    object = await object;
    object = object ? await mapObjectData(object,true) : null;
    customer = await customer;
    customer = customer ? await mapCustomerData(customer,true) : null;
    if (images) {
        images = await images.toArray();
        //images = await Promise.all(images.map((t) => mapImageData(t)));
    }
    return {
        id,
        edvObjAbgId,
        abgabeNr,
        abgabeLfdNr,
        betriebsNr,
        bauJahr,
        letztesEichJahr,
        eichIntervall,
        anzahlStellen,
        fabrikat,
        einbauDatum,
        wasserGenossenschaft,
        versorgungsKreis,
        anschlussDatum,
        buchNr,
        buchNrLfd,
        durchFlussGroesse,
        zaehlerArt,
        anfangsStand,
        anfangsStandDatum,
        endStand,
        endStandDatum,
        webAblesung,
        zlrVerbrLetztPer,
        deviceId,
        //device,
        objectId,
        object,
        customerId,
        customer,
        images,
        periods,
        tbId,
        createdAt,
        updatedAt
    };
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];

    filtersList.push(c.status.ne(MeteringPointStatus.REMOVED));
    if (filterOps?.meteringPointId) {
        filtersList.push(c.meteringPointId.eq(filterOps.meteringPointId));
    }
    if (filterOps?.letztesEichJahr) {
        const toYear = filterOps.letztesEichJahr;
        filtersList.push(c.letztesEichJahr.le(toYear));
    }
    if (filterOps?.einbauDatum) {
        const toDate = new Date(filterOps.einbauDatum);
        toDate.setDate(toDate.getDate() + 1);
        filtersList.push(c.einbauDatum.le(toDate.toISOString()));
    }
    if (filterOps?.endStandDatum) {
        const toDate = new Date(filterOps.endStandDatum);
        toDate.setDate(toDate.getDate() + 1);
        filtersList.push(c.endStandDatum.le(toDate.toISOString()));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    return c.and(c => mapFilters(c,filterOps));
}

export const meteringPointsStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let meteringPoints = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            meteringPoints = await DataStore.query(MeteringPoint, c => c.status.ne(MeteringPointStatus.REMOVED));
            // map meteringPoints
            meteringPoints = await Promise.all(meteringPoints.map((meteringPoint) => mapMeteringPointData(meteringPoint)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            meteringPoints = await DataStore.query(MeteringPoint,
                c => applySelectedFiltering(c,filter));
            meteringPoints = await Promise.all(meteringPoints.map((ret) => mapMeteringPointData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            meteringPoints = await DataStore.query(MeteringPoint,
                c => applyDataStoreFiltering(c,filter), {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map meteringPoints
            meteringPoints = await Promise.all(meteringPoints.map((meteringPoint) => mapMeteringPointData(meteringPoint)));
        }
        //console.log(meteringPoints)
        return {
            data: meteringPoints
        }
    },
    remove: (key) => {
        // not really deleting, just set the status flag to REMOVED
        return DataStore.query(MeteringPoint,key).then((original) => {
            console.log(original)
                DataStore.save(
                    MeteringPoint.copyOf(original, (updated) => {
                        updated["status"] = MeteringPointStatus.REMOVED;
                    })
                ).then(() => notify(`MeteringPoint ${original.meteringPointId}  wurde erfolgreich aktualisiert`, "success", 3000))
                    .catch((error) => {
                        notify(`MeteringPoint ${original.meteringPointId}  wurde nicht aktualisiert`, "error", 3000);
                        throw Error(error);
                    })
            })
            .catch((error) => {
                console.error(error);
                notify(`Kein MeteringPoint mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: (data) => {
        const id = uuidv4();
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from MeteringPoint: ${data.meteringPointId}`,
                        id);
                }
            })
        }
        return DataStore.save(
            new MeteringPoint({
                id: id,
                abgabeNr: data.abgabeNr,
                abgabeLfdNr: data.abgabeLfdNr,
                betriebsNr: data.betriebsNr,
                bauJahr: data.bauJahr,
                letztesEichJahr: data.letztesEichJahr,
                eichIntervall: data.eichIntervall,
                anzahlStellen: data.anzahlStellen,
                fabrikat: data.fabrikat,
                einbauDatum: data.einbauDatum ? dateToAWSDate(data.einbauDatum) : null,
                wasserGenossenschaft: data.wasserGenossenschaft,
                versorgungsKreis: data.versorgungsKreis,
                anschlussDatum: data.anschlussDatum ? dateToAWSDate(data.anschlussDatum) : null,
                buchNr: data.buchNr,
                buchNrLfd: data.buchNrLfd,
                durchFlussGroesse: data.durchFlussGroesse,
                zaehlerArt: data.zaehlerArt,
                anfangsStand: data.anfangsStand,
                anfangsStandDatum: data.anschlussDatum ? dateToAWSDate(data.anschlussDatum) : null,
                endStand: data.endStand,
                endStandDatum: data.anschlussDatum ? dateToAWSDate(data.anschlussDatum) : null,
                webAblesung: data.webAblesung,
                zlrVerbrLetztPer: data.zlrVerbrLetztPer,
                deviceId: data.deviceId,
                objectId: data.objectId,
                customerId: data.customerId,
                status: MeteringPointStatus.ACTIVE,
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN]

            })
        ).then(() => notify(`MeteringPoint wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`MeteringPoint wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from MeteringPoint: ${data.meteringPointId}`,
                        key);
                }
            })
        }
        return DataStore.query(MeteringPoint, key)
            .then((original) => DataStore.save(
                MeteringPoint.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        if (value instanceof Date) {
                            switch (key) {
                                case "einbauDatum":
                                case "anschlussDatum":
                                case "anfangsStandDatum":
                                case "endStandDatum":
                                    updated[key] = dateToAWSDate(value);
                                    break;
                                default:
                                    updated[key] = dateToAWSDateTime(value);
                                    break;
                            }
                        } else {
                            updated[key] = value;
                        }
                    }
                })).then(() => notify(`MeteringPoint ${original.meteringPointId}  wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`MeteringPoint ${original.meteringPointId}  wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein MeteringPoint mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(MeteringPoint, key)
            .then(async (original) => await mapMeteringPointData(original))
            .catch((error) => {
                notify(`Kein MeteringPoint mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapMeteringPointData(elem);
        switch(data.opType) {
            case "UPDATE":
                meteringPointsStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                meteringPointsStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                meteringPointsStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}