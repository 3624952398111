import CustomStore from 'devextreme/data/custom_store';
import { DataStore, Predicates } from "aws-amplify";
import { Customer, UserRole} from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering} from "../common/customDataStore";

export const mapCustomerData = async (customer) => {
    const {
        id,
        customerId,
        firstName,
        lastName,
        gender,
        phone,
        email,
        address,
        sensors,
        createdAt,
        updatedAt,
    } = customer;
    return {
        id,
        customerId,
        firstName,
        lastName,
        gender,
        phone,
        email,
        address,
        sensors,
        createdAt,
        updatedAt,
        name: `${lastName} ${firstName}`
    };
};

export const customersStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let customers = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            customers = await DataStore.query(Customer, Predicates.ALL);
            // map customers
            customers = await Promise.all(customers.map((customer) => mapCustomerData(customer)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            customers = await DataStore.query(Customer,
                c => applySelectedFiltering(c,filter));
            customers = await Promise.all(customers.map((customer) => mapCustomerData(customer)));
        } else { // paging
            const page = Math.floor(skip/take);
            customers = await DataStore.query(Customer,
                Predicates.ALL, {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map customers
            customers = await Promise.all(customers.map((customer) => mapCustomerData(customer)));
        }
        return {
            data: customers
        }
    },
    remove: (key) => {
        return DataStore.query(Customer, key)
            .then((original) => {
                    DataStore.delete(original)
                        .then(() =>
                            notify(`Customer ${original.customerId} wurde erfolgreich gelöscht`, "success", 3000)
                        ).catch((error) => {
                            notify(`Customer ${original.customerId} wurde nicht gelöscht`, "error", 3000)
                            throw Error(error);
                        })
                }
            )
            .catch((error) => {
                notify(`Kein Customer mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: (data) => {
        const id = uuidv4();
        return DataStore.save(
            new Customer({
                id: id,
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                phone: data.phone,
                email: data.email,
                address: data.address,
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN],
            })
        ).then(() => notify(`Customer wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`Customer wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        return DataStore.query(Customer, key)
            .then((original) => DataStore.save(
                Customer.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        updated[key] = value;
                    }
                })).then(() => notify(`Customer ${original.customerId}  wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Customer ${original.customerId}  wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein Customer mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(Customer, key)
            .then(async (original) => await mapCustomerData(original))
            .catch((error) => {
                notify(`Keine Customer mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapCustomerData(elem);
        switch(data.opType) {
            case "UPDATE":
                customersStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                customersStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                customersStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}