import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {
    Item, RequiredRule,
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import "devextreme-react/select-box";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore, Hub } from "aws-amplify";
import { Device, UserRole } from "../../models";
import {useTranslation} from "react-i18next";
import {devicesStore, handleSubscriptionEvent} from '../../data/devicesStore';
import {customersStore} from '../../data/customersStore';
import { CheckBox } from 'devextreme-react/check-box';
import QrCodeScannerTextBox from '../QrCodeScannerTextBox';
import PageHeader from "../PageHeader/PageHeader";
import ImagesItem from "../ImagesItem/ImagesItem";

function DeviceManagementView() {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const deviceStatus = t('deviceStatus',{ returnObjects: true });
    const deviceType = t('deviceType',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState([]);
    const datagrid = useRef();

    const getFilteredDeviceStatus = (options) => {
        let filter = null;
        return {
            store: deviceStatus,
            filter:filter
        };
    }

    const getFilteredDeviceType = (options) => {
        let filter = null;
        return {
            store: deviceType,
            filter:filter
        };
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addDevice = async (event) => {
        console.log(event)
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Deviceen anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editDevice = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Deviceen bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeDevice = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Deviceen löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
        event.data.temporaryPlacement=false;
    }

    useEffect(() => {
        const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                datagrid?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);
        const subscription = DataStore.observe(Device).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="DeviceManagementView">
                <PageHeader headerText={t('deviceManagementView.header')} />
                <DataGrid
                    ref={ref => datagrid.current = ref}
                    dataSource={devicesStore}
                    defaultFilterValue={[]}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    id="devices"
                    onRowInserting={e => addDevice(e)}
                    onRowUpdating={e => editDevice(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeDevice(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('deviceManagementView.popupTitle1') : t('deviceManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={2} showValidationSummary={true}>
                                <Item
                                    dataField="deviceId"
                                    colSpan={1}>
                                    <RequiredRule/>
                                </Item>
                                <Item dataField="customerId" colSpan={1}/>
                                <Item dataField="type" colSpan={1}>
                                    <RequiredRule/>
                                </Item>
                                <Item dataField="status" colSpan={1}>
                                    <RequiredRule/>
                                </Item>
                                <Item dataField="calibrationDate" colSpan={1}/>
                                <Item dataField="productionDate" colSpan={1}/>
                                <Item
                                    dataField="producer"
                                    colSpan={1} />
                                <Item dataField="flowRate"
                                      colSpan={1}
                                      editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0,
                                          showSpinButtons:true
                                      }}
                                />
                                <Item dataField="decimalPlaces"
                                      colSpan={1}
                                      editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0,
                                          showSpinButtons:true
                                      }}
                                />
                                <Item dataField="temporaryPlacement" editorType="dxCheckBox" colSpan={1}/>
                                <Item dataField="mountDate" colSpan={1}/>
                                <Item dataField="latestMeterReadingDate" colSpan={1}/>
                                <Item
                                    dataField="images"
                                    colSpan={1}/>
                            </Form>
                        </Editing> : null}
                    <Column dataField="deviceId"
                            caption={t('deviceManagementView.deviceId')}
                            dataType={"string"}
                            defaultSortOrder={"asc"}
                            hidingPriority={11}
                            /* the following is experimental*/
                            editCellRender={(cell) => <QrCodeScannerTextBox cell={cell}/>}
                    />
                    <Column dataField="customerId"
                            caption={t('deviceManagementView.customerId')}
                            dataType={"string"}
                            hidingPriority={9}>
                        <Lookup dataSource={customersStore} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="type" caption={t('deviceManagementView.type')} dataType={"string"} hidingPriority={7}>
                        <Lookup dataSource={getFilteredDeviceType} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="status" caption={t('deviceManagementView.status')} dataType={"string"} hidingPriority={8}>
                        <Lookup dataSource={getFilteredDeviceStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="calibrationDate" caption={t('deviceManagementView.calibrationDate')}
                            dataType="date"
                            hidingPriority={6}/>
                    <Column dataField="productionDate" caption={t('deviceManagementView.productionDate')}
                            dataType="date"
                            hidingPriority={5}/>
                    <Column dataField="producer" caption={t('deviceManagementView.producer')} hidingPriority={4}/>
                    <Column dataField="flowRate" caption={t('deviceManagementView.flowRate')}
                            dataType="number"
                            hidingPriority={3}/>
                    <Column dataField="decimalPlaces" caption={t('deviceManagementView.decimalPlaces')}
                            dataType="number"
                            hidingPriority={2}/>
                    <Column dataField="temporaryPlacement" caption={t('deviceManagementView.temporaryPlacement')} hidingPriority={1}/>
                    <Column dataField="latestMeterReadingDate"
                            caption={t('deviceManagementView.latestMeterReadingDate')}
                            dataType="datetime"
                            hidingPriority={10}/>
                    <Column dataField="mountDate"
                            caption={t('deviceManagementView.mountDate')}
                            dataType="datetime"
                            hidingPriority={11}/>
                    <Column dataField="images"
                            caption={t('deviceManagementView.images')}
                            hidingPriority={9}
                            editCellRender={(cell) => <ImagesItem cell={cell}/>}
                            visible={false}
                    >
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default DeviceManagementView;
