import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting, Button as DataGridButton
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import {
    Item, RequiredRule, AsyncRule, EmptyItem, GroupItem
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore } from "aws-amplify";
import { Tour, TourTask, TourTaskType, UserRole, Device } from "../../models";
import {useTranslation} from "react-i18next";
import {mapTourTaskData, applySorting, applyDataStoreFiltering} from "../../data/tourTasksStore";
import {usersStore} from "../../data/usersStore";
import {mapTourData} from "../../data/toursStore";
import {uploadImage} from "../../common/customDataStore";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, dateToAWSDateTime, dateToAWSDate} from "../../common/customDataStore";
import PageHeader from "../PageHeader/PageHeader";
import ImagesItem from "../ImagesItem/ImagesItem";


const renderDeviceCell = (data) => {
    if (data.value) {
        return (
            <div className={"device"}>
                <span>{data.text}</span>
            </div>
        )}
}

function TourTaskManagementView({periodId, tourId}) {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const tourTaskStatus = t('tourStatus',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    const [tour, setTour] = useState({});
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState([]);
    const dataGridRef = useRef(null);

    const tourTasksStore = new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
            const {skip, take, filter, sort, isLoadingAll} = loadOptions;
            let tourTasks = [];
            if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
                tourTasks = await DataStore.query(TourTask, c => applyDataStoreFiltering(c,{periodId, tourId}));
                // map tourTasks
                tourTasks = await Promise.all(tourTasks.map((tourTask) => mapTourTaskData(tourTask)));
            } else if (Array.isArray(filter)) { // handle filter for selected rows
                tourTasks = await DataStore.query(TourTask,
                    c => applySelectedFiltering(c,filter));
                tourTasks = await Promise.all(tourTasks.map((ret) => mapTourTaskData(ret)));
            } else { // paging
                const page = Math.floor(skip/take);
                tourTasks = await DataStore.query(TourTask,
                    c => applyDataStoreFiltering(c,{...filter, periodId, tourId}), {
                        page: page,
                        limit: take ? take : 50,
                        sort: s => applySorting(s, sort)
                    } );
                // map tourTasks
                tourTasks = await Promise.all(tourTasks.map((tourTask) => mapTourTaskData(tourTask)));
            }
            //console.log(tourTasks)
            return {
                data: tourTasks
            }
        },
        remove: (key) => {
            return DataStore.query(TourTask, key)
                .then(async (original) => {
                        // delete the tourTask
                        DataStore.delete(original)
                            .then(() =>
                                notify(`TourTask ${original.id} wurde erfolgreich gelöscht`, "success", 3000)
                            ).catch((error) => {
                            notify(`TourTask ${original.id} wurde nicht gelöscht`, "error", 3000)
                            throw Error(error);
                        })
                    }
                )
                .catch((error) => {
                    notify(`Kein TourTask mit ID ${key} gefunden`, "error", 3000);
                    throw Error(error);
                });
        },
        insert: async (data) => {
            const id = uuidv4();
            try {
                if (data.images) {
                    // upload images first
                    data.images.forEach((img) => {
                        if (img.isNew === true) {
                            uploadImage(uuidv4(), img.name,
                                img.content,
                                `Image from Buch. Nr. Lfd.: ${data.buchNrLfd}`,
                                id);
                        }
                    })
                }
                
                const tourTask = await DataStore.save(
                    new TourTask({
                        id: id,
                        tourId: data.tourId,
                        buchNrLfd: data.buchNrLfd,
                        meteringPointId: data.meteringPoint ? data.meteringPoint.id : null,
                        type: data.type,
                        //oldDeviceId: data.oldDevice ? data.oldDevice.id : null,
                        //deviceId: data.deviceId ? data.deviceId : null,
                        date: dateToAWSDate(data.date),
                        comment: data.comment,
                        accomplishedAt: dateToAWSDateTime(data.accomplishedAt),
                        accomplishedUserId: data.assignedToUserId,
                        status: data.status,
                        createdAt: dateToAWSDateTime(new Date()),
                        updatedAt: dateToAWSDateTime(new Date()),
                        groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                        groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN]
                    }));
                notify(`TourTask wurde erfolgreich angelegt`, "success", 3000);
            } catch(err) {
                console.error(err);
                notify(`TourTask wurde nicht angelegt`, "error", 3000);
                throw Error(err);
            }
        },
        update: (key, data) => {
            if (data.images) {
                // upload images first
                data.images.forEach((img) => {
                    if (img.isNew === true) {
                        uploadImage(uuidv4(), img.name,
                            img.content,
                            `Image from Buch. Nr. Lfd.: ${data.buchNrLfd}`,
                            key);
                    }
                })
            }
            return DataStore.query(TourTask, key)
                .then((original) => DataStore.save(
                    TourTask.copyOf(original, (updated) => {
                        for(const [key, value] of Object.entries(data)) {
                            switch (key) {
                                case "date":
                                    updated[key] = dateToAWSDate(value);
                                    break;
                                case "accomplishedAt":
                                    updated[key] = dateToAWSDateTime(value);
                                    break;
                                default:
                                    updated[key] = value;
                                    break;
                            }
                        }
                    })).then(() => notify(`TourTask wurde erfolgreich aktualisiert`, "success", 3000))
                    .catch((error) => {
                        notify(`TourTask wurde nicht aktualisiert`, "error", 3000);
                        throw Error(error);
                    })
                )
                .catch((error) => {
                    notify(`Kein TourTask mit ID ${key} gefunden`, "error", 3000);
                    throw Error(error);
                });
        },
        byKey: (key) => {
            return DataStore.query(TourTask, key)
                .then(async (original) => await mapTourTaskData(original))
                .catch((error) => {
                    notify(`Kein TourTask mit ID ${key} gefunden`, "error", 3000);
                    throw Error(error);
                });
        }
    });

    const checkBuchNrLfdIsUnique = async(params) => {
        let buchNrLfd = params.value;
        try {
            const tourTasks = await DataStore.query(TourTask,  c => c.and(c => [c.buchNrLfd.eq(buchNrLfd), c.tourId.eq(tourId)]));
            return (tourTasks.length === 0)
        } catch (err) { console.error(err) }
        return false;
    }

    async function handleSubscriptionEvent(data) {
        try {
            const elem = data.element;
            const transformed_data = mapTourTaskData(elem);
            switch(data.opType) {
                case "UPDATE":
                    tourTasksStore.push([{
                        type: "update",
                        data: transformed_data,
                        key: elem.id}]);
                    break;
                case "INSERT":
                    tourTasksStore.push([{
                        type: "insert",
                        data: transformed_data}]);
                    break;
                case "DELETE":
                    tourTasksStore.push([{ type: "remove", key: elem.id }]);
                    break;
                default: break;
            }
        } catch (err) {
            console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
        }
    }

    const getFilteredTourTaskStatus = (options) => {
        let filter = null;
        return {
            store: tourTaskStatus,
            filter:filter
        };
    }

    const devicesOfPeriodStore = new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
            let devices = await DataStore.query(Device, (d) => d.periods.period.id.eq(periodId)).then(devices => {return devices})
            return {
                data: devices
            }
        },
        byKey: (key) => {
            return DataStore.query(Device, key)
                .then((original) => {return original})
                .catch((error) => {
                    throw Error(error);
                });
        }
    });

    const getFilteredUsers = (options) => {
        let filter = [['role', '=', 'Technician']];
        return {
            store: usersStore,
            filter:filter
        };
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addTourTask = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine TourTasks anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editTourTask = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine TourTasks bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeTourTask = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine TourTasks löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
        event.data.tourId=tourId;
        event.data.type=TourTaskType.DEVICE_CHANGE;
    }

    useEffect(() => {
        /*const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                dataGridRef?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);*/
        DataStore.query(Tour, tourId)
            .then(async (result) => {
                setTour(await mapTourData(result));
            }
            );
        const subscription = DataStore.observe(TourTask).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            //Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="TourTaskManagementView">
                <PageHeader
                    headerText={t('tourTaskManagementView.header')}
                    backButtonText={t('tourTaskManagementView.backButtonText')}/>
                <DataGrid
                    ref={ref => dataGridRef.current = ref}
                    dataSource={tourTasksStore}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: true,
                        filtering: false
                    }}
                    id="tourTasks"
                    onRowInserting={e => addTourTask(e)}
                    onRowUpdating={e => editTourTask(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeTourTask(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={false}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('tourTaskManagementView.popupTitle1') : t('tourTaskManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={2} showValidationSummary={true}>
                                <GroupItem caption={t('tourTaskManagementView.groupItem1')} colCount={2} colSpan={2}>
                                    <Item
                                        dataField="buchNrLfd"
                                        colSpan={1}
                                        editorType="dxNumberBox"
                                        editorOptions={{
                                            min:0,
                                            showSpinButtons:true
                                        }}>
                                        <RequiredRule/>
                                        { !editing ?
                                            <AsyncRule message={t('tourTaskManagementView.buchNrLfdAsyncRuleMsg')} validationCallback={checkBuchNrLfdIsUnique}/>
                                            : null
                                        }
                                    </Item>
                                    <Item dataField="date" colSpan={1}/>
                                    <Item dataField="status" colSpan={1}>
                                        <RequiredRule/>
                                    </Item>
                                <EmptyItem/>
                                <Item dataField="comment" editorType="dxTextArea" editorOptions={{height: "250"}} colSpan={2}/>
                                    <Item
                                        dataField="images"
                                        colSpan={2}/>
                                </GroupItem>
                                <GroupItem caption={t('tourTaskManagementView.groupItem2')} colCount={2} colSpan={2}>
                                    <Item dataField="accomplishedAt" colSpan={1}/>
                                    <Item dataField="accomplishedUserId" colSpan={1}/>
                                </GroupItem>
                            </Form>
                        </Editing>
                    <Column dataField="buchNrLfd"
                            caption={t('tourTaskManagementView.buchNrLfd')}
                            dataType={"number"}
                            defaultSortOrder={"asc"}
                            allowEditing={!editing}
                            hidingPriority={11}
                            />
                    <Column dataField="date"
                            caption={t('tourTaskManagementView.date')}
                            dataType="date"
                            hidingPriority={6}/>
                    <Column dataField="meteringPoint.deviceId"
                            caption={t('tourTaskManagementView.deviceId')}
                            dataType={"string"}
                            hidingPriority={3}/>
                    <Column dataField="status" caption={t('tourTaskManagementView.status')}
                            dataType={"string"}
                            hidingPriority={8}>
                        <Lookup dataSource={getFilteredTourTaskStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="comment"
                            caption={t('tourTaskManagementView.comment')}
                            dataType="string"
                            hidingPriority={4}/>
                    <Column dataField="accomplishedAt" caption={t('tourTaskManagementView.accomplishedAt')}
                            dataType="datetime"
                            hidingPriority={5}
                    />
                    <Column dataField="accomplishedUserId"
                            caption={t('tourTaskManagementView.accomplishedUserId')}
                            dataType={"string"}
                            hidingPriority={9}>
                        <Lookup dataSource={getFilteredUsers} valueExpr={"id"} displayExpr={"lastName"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="images"
                            caption={t('tourTaskManagementView.images')}
                            hidingPriority={2}
                            editCellRender={(cell) => <ImagesItem cell={cell}/>}
                            visible={false}
                    />
                    <Column type="buttons">
                        <DataGridButton name="edit" />
                        <DataGridButton name="delete" />
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default TourTaskManagementView;
