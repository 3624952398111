import CustomStore from 'devextreme/data/custom_store';
import { DataStore } from "aws-amplify";
import { Device, DeviceStatus, UserRole } from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, uploadImage, dateToAWSDate, dateToAWSDateTime} from "../common/customDataStore";

export const mapDeviceData = async (device) => {
    let {
        id,
        deviceId,
        customerId,
        customer,
        type,
        status,
        calibrationDate,
        prductionDate,
        mountDate,
        producer,
        flowRate,
        decimalPlaces,
        temporaryPlacement,
        images,
        latestMeterReadingDate,
        meterReads,
        periods,
        createdAt,
        updatedAt
    } = device;
    if (images) {
        images = await images.toArray();
        //images = await Promise.all(images.map((t) => mapImageData(t)));
    }
    return {
        id,
        deviceId,
        customerId,
        customer,
        type,
        status,
        calibrationDate,
        prductionDate,
        mountDate,
        producer,
        flowRate,
        decimalPlaces,
        temporaryPlacement,
        images,
        latestMeterReadingDate,
        meterReads,
        periods,
        createdAt,
        updatedAt
    };
};

const mapFilters = (c, filterOps) => {
    let filtersList = [];
    // filter by default all not removed devices
    filtersList.push(c.status.ne(DeviceStatus.REMOVED));
    if (filterOps?.deviceId) {
        filtersList.push(c.deviceId.eq(filterOps.deviceId));
    }
    if (filterOps?.calibrationDate) {
        const toDate = new Date(filterOps.calibrationDate);
        toDate.setDate(toDate.getDate() + 1);
        filtersList.push(c.calibrationDate.le(toDate.toISOString()));
    }
    if (filterOps?.mountDate) {
        const toDate = new Date(filterOps.mountDate);
        toDate.setDate(toDate.getDate() + 1);
        filtersList.push(c.mountDate.le(toDate.toISOString()));
    }
    if (filterOps?.latestMeterReadingDate) {
        const toDate = new Date(filterOps.latestMeterReadingDate);
        toDate.setDate(toDate.getDate() + 1);
        filtersList.push(c.latestMeterReadingDate.le(toDate.toISOString()));
    }
    return filtersList;
}

const applyDataStoreFiltering = (c, filterOps) => {
    return c.and(c => mapFilters(c,filterOps));
}

export const devicesStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let devices = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            devices = await DataStore.query(Device, c => c.status.ne(DeviceStatus.REMOVED));
            // map devices
            devices = await Promise.all(devices.map((device) => mapDeviceData(device)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            devices = await DataStore.query(Device,
                c => applySelectedFiltering(c,filter));
            devices = await Promise.all(devices.map((ret) => mapDeviceData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            devices = await DataStore.query(Device,
                c => applyDataStoreFiltering(c,filter), {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map devices
            devices = await Promise.all(devices.map((device) => mapDeviceData(device)));
        }
        //console.log(devices)
        return {
            data: devices
        }
    },
    remove: (key) => {
        // not really deleting, just set the status flag to REMOVED
        return DataStore.query(Device,key).then((original) => {
            console.log(original)
                DataStore.save(
                    Device.copyOf(original, (updated) => {
                        updated["status"] = DeviceStatus.REMOVED;
                    })
                ).then(() => notify(`Device ${original.deviceId}  wurde erfolgreich aktualisiert`, "success", 3000))
                    .catch((error) => {
                        notify(`Device ${original.deviceId}  wurde nicht aktualisiert`, "error", 3000);
                        throw Error(error);
                    })
            })
            .catch((error) => {
                console.error(error);
                notify(`Kein Device mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: (data) => {
        const id = uuidv4();
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from Device: ${data.deviceId}`,
                        id);
                }
            })
        }
        return DataStore.save(
            new Device({
                id: id,
                deviceId: data.deviceId,
                customerId: data.customerId,
                type: data.type,
                status: data.status,
                calibrationDate: data.calibrationDate ? dateToAWSDate(data.calibrationDate) : null,
                prductionDate: data.productionDate ? dateToAWSDate(data.productionDate) : null,
                mountDate: data.mountDate ? dateToAWSDateTime(data.mountDate) : null,
                producer: data.producer,
                flowRate: data.flowRate,
                decimalPlaces: data.decimalPlaces,
                temporaryPlacement: data.temporaryPlacement,
                latestMeterReadingDate: data.latestMeterReadingDate ? dateToAWSDateTime(data.latestMeterReadingDate) : null,
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN, UserRole.TECHNICIAN]
            })
        ).then(() => notify(`Device wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`Device wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from Device: ${data.deviceId}`,
                        key);
                }
            })
        }
        return DataStore.query(Device, key)
            .then((original) => DataStore.save(
                Device.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        if (value instanceof Date) {
                            switch (key) {
                                case "calibrationDate":
                                case "productionDate":
                                    updated[key] = dateToAWSDate(value);
                                    break;
                                default:
                                    updated[key] = dateToAWSDateTime(value);
                                    break;
                            }
                        } else {
                            updated[key] = value;
                        }
                    }
                })).then(() => notify(`Device ${original.deviceId}  wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Device ${original.deviceId}  wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein Device mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(Device, key)
            .then(async (original) => await mapDeviceData(original))
            .catch((error) => {
                notify(`Kein Device mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapDeviceData(elem);
        switch(data.opType) {
            case "UPDATE":
                devicesStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                devicesStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                devicesStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}