import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting, Button as DataGridButton
} from 'devextreme-react/data-grid';
import {
    Item, SimpleItem, EmptyItem, RequiredRule, AsyncRule,
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore } from "aws-amplify";
import { Tour, UserRole, PeriodMeteringPoint, MeteringPoint } from "../../models";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";
import {toursStore, handleSubscriptionEvent} from "../../data/toursStore";
import {usersStore} from "../../data/usersStore";
import PageHeader from "../PageHeader/PageHeader";
import TextArea from "devextreme/ui/text_area";
import CustomStore from 'devextreme/data/custom_store';

function TourManagementView({periodId}) {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const tourStatus = t('tourStatus',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState(['periodId','=', periodId]);
    const dataGridRef = useRef(null);

    const getFilteredTourStatus = (options) => {
        let filter = null;
        return {
            store: tourStatus,
            filter:filter
        };
    }

    const buchNrStore = new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
            const periodMeteringPoints = await DataStore.query(PeriodMeteringPoint, c => c.periodID.eq(periodId));
            const meteringPoints = await Promise.all(periodMeteringPoints.map(async pmp => await DataStore.query(MeteringPoint, pmp.meteringPointID)));
            return {
                data: [...new Set(meteringPoints.map(mp => mp.buchNr))]
            }
        },
        byKey: (key) => {
            return key;
        }
    });

    const getFilteredUsers = (options) => {
        let filter = [['role', '=', 'Technician']];
        return {
            store: usersStore,
            filter:filter
        };
    }

    const checkBuchNrIsUnique = async(params) => {
        let buchNr = params.value;
        try {
            const tours = await DataStore.query(Tour,  c => c.and(c => [c.buchNr.eq(buchNr), c.periodId.eq(periodId)]));
            return (tours.length === 0)
        } catch (err) { console.error(err) }
        return false;
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addTour = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Touren anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editTour = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Touren bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeTour = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Touren löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
        event.data.periodId=periodId;
        event.data.status= "CREATED";
    }

    const forwardToTasksTechnicianView = (event) => {
        const periodId = event.row.data.periodId;
        const tourId = event.row.data.id;
        const path = window.location.pathname.split('/');
        navigate(`/${path[1]}/${periodId}/${tourId}/tasks`);
    }

    useEffect(() => {
        /*const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                dataGridRef?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);*/
        const subscription = DataStore.observe(Tour).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            //Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    useEffect(() => {
        setFilter(['periodId','=', periodId]);
    }, [periodId]);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="TourManagementView">
                <PageHeader
                    headerText={t('tourManagementView.header')}
                    backButtonText={t('tourManagementView.backButtonText')}
                />
                <DataGrid
                    ref={ref => dataGridRef.current = ref}
                    dataSource={toursStore}
                    defaultFilterValue={['periodId','=', periodId]}
                    filterValue={filter}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: true,
                        filtering: true
                    }}
                    id="tours"
                    onRowInserting={e => addTour(e)}
                    onRowUpdating={e => editTour(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeTour(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('tourManagementView.popupTitle1') : t('tourManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={2} showValidationSummary={true}>
                                <Item
                                    dataField="buchNr"
                                    colSpan={1}>
                                    <RequiredRule message={t('tourManagementView.buchNrRequiredRuleMsg')}/>
                                    {!editing ?
                                        <AsyncRule message={t('tourManagementView.buchNrAsyncRuleMsg')}
                                                   validationCallback={checkBuchNrIsUnique}/>
                                        : null
                                    }
                                </Item>
                                <Item dataField="date" colSpan={1}>
                                    <RequiredRule />
                                </Item>
                                <Item dataField="assignedToUserId" colSpan={1}>
                                    <RequiredRule />
                                </Item>
                                <Item dataField="accomplishedAt" colSpan={1}/>
                                <Item dataField="status" colSpan={1}>
                                    <RequiredRule message={t('tourManagementView.statusRequiredRuleMsg')}/>
                                </Item>
                                <EmptyItem/>
                                <SimpleItem dataField="comment" editorType="dxTextArea" editorOptions={{height: "200"}} colSpan={2}/>
                            </Form>
                        </Editing>
                    <Column dataField="buchNr"
                            caption={t('tourManagementView.buchNr')}
                            defaultSortOrder={"asc"}
                            hidingPriority={11}>
                        <Lookup dataSource={buchNrStore} searchEnabled={true}/>
                    </Column>
                    <Column dataField="status" caption={t('tourManagementView.status')}
                            dataType={"string"}
                            hidingPriority={8}>
                        <Lookup dataSource={getFilteredTourStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="date"
                            caption={t('tourManagementView.date')}
                            dataType="date"
                            hidingPriority={5}/>
                    <Column dataField="comment"
                            caption={t('tourManagementView.comment')}
                            hidingPriority={4}/>
                    <Column dataField="assignedToUserId"
                            caption={t('tourManagementView.assignedToUserId')}
                            dataType={"string"}
                            hidingPriority={9}>
                        <Lookup dataSource={getFilteredUsers} valueExpr={"id"} displayExpr={"lastName"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="accomplishedAt" caption={t('tourManagementView.accomplishedAt')}
                            dataType="datetime"
                            hidingPriority={6}/>
                    <Column dataField="periodId" caption={t('tourManagementView.periodId')} dataType={"string"} visible={false} />
                    <Column type="buttons">
                        <DataGridButton hint={t('tourManagementView.tourTaskBtnHint')} icon="fa-solid fa-briefcase" visible={true} disabled={false} onClick={(e) => forwardToTasksTechnicianView(e)} />
                        <DataGridButton name="edit" />
                        <DataGridButton name="delete" />
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default TourManagementView;
