import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {
    Item, RequiredRule, AsyncRule, Label,
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import "devextreme-react/select-box";
import {Scrolling} from "devextreme-react/tree-list";
import { DataStore, Hub } from "aws-amplify";
import { User, UserRole, UserStatus} from "../../models";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faLockOpen, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {usersStore, handleSubscriptionEvent} from '../../data/usersStore';
import PageHeader from "../PageHeader/PageHeader";

function UserManagementView() {
    const [t] = useTranslation();
    const status = t('userStatus',{ returnObjects: true });
    const roles = t('userRoles',{ returnObjects: true });
    const filteringValues = t('userFilteringValues',{ returnObjects: true });
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState(null);
    const datagrid = useRef(null);

    const checkEmailAlreadyExists = async(params) => {
        let email = params.value;
        try {
            const users = await DataStore.query(User, (user) => user.email.eq(email));
            return (users.length === 0)
        } catch (err) { console.error(err) }
        return false;
    }

    const getFilteredRoles = (options) => {
        let filter = null;
        return {
            store: roles,
            filter:filter
        };
    }

    const getFilteredStatus = (options) => {
        let filter = null;
        return {
            store: status,
            filter:filter
        };
    }

    const renderStatusCell = (data) => {
        switch(data.value) {
            case 'ACTIVE':
                return <FontAwesomeIcon className={"userStatus active"} icon={faLockOpen} />
            case 'DEACTIVATED':
                return <FontAwesomeIcon className={"userStatus deactivated"} icon={faLock} />
            //case 'REMOVED':
            //    return <FontAwesomeIcon className={"userStatus removed"} icon={faUserSlash} />
            default:
                return <FontAwesomeIcon className={"userStatus removed"} icon={faCircleQuestion} />
        }
    }

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return ([UserRole.ADMIN].includes(userContext.userInfoFromDB.role)) && !([UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(e.row.data.role));
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        // its own data or admins/superadmins can edit citizens
        return (e.row.data.id === userContext.userInfoFromDB.id) ||
            (([UserRole.ADMIN].includes(userContext.userInfoFromDB.role)) &&
        !([UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(e.row.data.role)));
    }

    const addUser = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Benutzer anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editUser = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Benutzer bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeUser = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine Benutzer löschen`);
            event.cancel = true;
            return;
        }
        if (userContext.userInfoFromDB.role === UserRole.ADMIN && event.data.role === UserRole.ADMIN) {
            alert(`Ein Admin darf keinen Admin löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        setEditing(false);
        event.data.status='ACTIVE';
        event.data.role=UserRole.TECHNICIAN;
    }

    useEffect(() => {
        const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            //console.log("DataStore event", event);
            if (event === 'ready') {
                datagrid?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);
        // apparently this is important
        const subscription = DataStore.observe(User).subscribe(handleSubscriptionEvent);
        /*DataStore.start()
            .catch(() => {
                DataStore.clear().then(() => {
                    DataStore.start();
                    subscription = DataStore.observe(User).subscribe(handleSubscriptionEvent);
                });
            })
            .then(() => {
                subscription = DataStore.observe(User).subscribe(handleSubscriptionEvent);
            });*/
        return () => {
            subscription.unsubscribe();
            Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="UserManagementView">
                <PageHeader headerText={t('userManagementView.header')} />
                <DataGrid
                    ref={ref => datagrid.current = ref}
                    dataSource={usersStore}
                    defaultFilterValue={[]}
                    filterValue={filter ? ["role", "=", filter] : []}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    id="users"
                    onRowInserting={e => addUser(e)}
                    onRowUpdating={e => editUser(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeUser(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem location={"before"} locateInMenu={"auto"}>
                            <SelectBox
                                width="auto"
                                items={filteringValues}
                                displayExpr="text"
                                valueExpr="value"
                                value={filter}
                                onValueChanged={(event) => {setFilter(event.value)}} />
                        </TItem>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('userManagementView.popupTitle1') : t('userManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={1} showValidationSummary={true}>
                                <Item dataField="firstName" colSpan={1}>
                                    <RequiredRule message={"Vorname ist erforderlich"}/>
                                </Item>
                                <Item dataField="lastName" colSpan={1}>
                                    <RequiredRule message={"Nachname ist erforderlich"}/>
                                </Item>
                                <Item dataField="email" colSpan={1}>
                                    <RequiredRule />
                                    {!editing ?
                                        <AsyncRule message={t('userManagementView.emailAsyncRuleMsg')} validationCallback={checkEmailAlreadyExists}/>
                                        :
                                        null
                                    }
                                </Item>
                                <Item dataField={"role"} colSpan={1}>
                                    <RequiredRule message={t('userManagementView.roleRequiredRuleMsg')}/>
                                </Item>
                                <Item dataField={"status"} colSpan={1}>
                                    <RequiredRule message={t('userManagementView.statusRequiredRuleMsg')}/>
                                </Item> : null}
                            </Form>
                        </Editing> : null}
                    <Column dataField="lastName"
                            caption={t('userManagementView.lastName')}
                            dataType={"string"}
                            defaultSortOrder={"asc"}
                            hidingPriority={4}/>
                    <Column dataField="firstName"
                            caption={t('userManagementView.firstName')}
                            dataType={"string"}
                            hidingPriority={0}
                    />
                    <Column dataField="email" caption={t('userManagementView.email')} dataType={"string"} allowEditing={!editing} hidingPriority={2}/>
                    <Column dataField="role" caption={t('userManagementView.role')} dataType={"string"} hidingPriority={3}>
                        <Lookup dataSource={getFilteredRoles} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="status" caption={t('userManagementView.status')} dataType={"string"} cellRender={renderStatusCell} hidingPriority={1}>
                        <Lookup dataSource={getFilteredStatus} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default UserManagementView;
