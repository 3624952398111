import React, {useState, useEffect} from "react";
import Gallery from 'devextreme-react/gallery';
import { Button } from 'devextreme-react/button';
import {useTranslation} from "react-i18next";
import {Storage} from 'aws-amplify';
import CameraPopup from "../CameraPopup/CameraPopup";
import notify from 'devextreme/ui/notify';

const gallerySettings = {
    loop: false,
    slideShow: false,
    showNavButtons: true,
    showIndicator: true,
    slideshowDelay: 0
};

const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], {type: mimeString});
}

const renderImage = (item) => <img src={item.imgUrl} alt={item.name} onClick={(e) => console.log(e)}/>

export default function ImagesItem({ cell }) {
    const [images, setImages] = useState([]); // list of paths/urls
    const [showCameraPopup, setShowCameraPopup] = useState(false);
    const [t] = useTranslation();

    useEffect(() => {
        // map the list of images to signed S3 urls
        if (cell.value) {
            //console.log(cell.value)
            const imagesList = cell.value;
            Promise.all(imagesList.map(async (img) => {
                return {...img, imgUrl: await Storage.get(img.file.key.replace("public/", ""), {
                    bucket: img.file.bucket,
                    download: false
                })}
            })).then((imgs) => setImages(imgs))
        }
    }, []);

    return (
        <div className={"ImagesList"}>
            {images && images.length > 0 ?
                <Gallery
                    id="gallery"
                    dataSource={images}
                    height={"auto"}
                    slideshowDelay={gallerySettings.slideshowDelay}
                    loop={gallerySettings.loop}
                    showNavButtons={gallerySettings.showNavButtons}
                    showIndicator={gallerySettings.showIndicator}
                    itemRender={renderImage}
                />
                :
                <div>{t('imagesItem.noDataText')}</div>
            }
            <Button
                width={"100%"}
                icon={"add"}
                hint={t('imagesItem.addButtonHint')}
                type="normal"
                stylingMode="text"
                onClick={() => setShowCameraPopup(true)}
            />
            {showCameraPopup ?
                <CameraPopup
                    onHiding={() => setShowCameraPopup(false)}
                    onCameraError={(error) => notify("Fehler bei Kamera Popup", "error", 3000)}
                    onTakePhoto={(dataURI) => {
                        const name = `img-${(new Date().getTime())}.jpg`;
                        const content = dataURItoBlob(dataURI);
                        const imgList = images.concat({
                            imgUrl: dataURI,
                            content: content,
                            name: name,
                            isNew: true
                        });
                        setImages(imgList);
                        cell.setValue(imgList);
                        setShowCameraPopup(false);
                        notify("Foto wurde zur Liste hinzugefügt.", "success", 3000)
                    }}
                />
                :
                null
            }
        </div>
    );
}
