import { DataStore, SortDirection } from "aws-amplify";
import { TourTask, Device} from "../models";
import {applySelectedFiltering, dateToAWSDateTime, dateToAWSDate} from "../common/customDataStore";
import {mapDeviceData} from "./devicesStore";
import {mapTourData} from "./toursStore";
import {mapMeteringPointData} from "./meteringPointsStore";

export const mapTourTaskData = async (tourTask) => {
    let {
        id,
        tourId,
        tour,
        buchNrLfd,
        meteringPointId,
        meteringPoint,
        type,
        //oldDeviceId,
        //deviceId,
        date,
        comment,
        accomplishedAt,
        accomplishedUserId,
        images,
        status,
        createdAt,
        updatedAt
    } = tourTask;
    tour = await tour;
    tour = tour ? await mapTourData(tour): null;
    meteringPoint = await meteringPoint;
    meteringPoint = meteringPoint ? await mapMeteringPointData(meteringPoint) : null;
    if (images) {
        images = await images.toArray();
        //images = await Promise.all(images.map((t) => mapImageData(t)));
    }
    //let oldDevice = oldDeviceId ? await DataStore.query(Device, oldDeviceId) : null;
    //let device = deviceId ? await DataStore.query(Device, deviceId) : null;
    return {
        id,
        tourId,
        tour,
        buchNrLfd,
        meteringPoint,
        type,
        //oldDeviceId,
        //oldDevice,
        //deviceId,
        //device,
        date,
        comment,
        accomplishedAt,
        accomplishedUserId,
        images,
        status,
        createdAt,
        updatedAt
    };
};

export const applySorting = (sortProxy, sortingOps) => {
    if (sortingOps) {
        sortingOps.forEach((op) => {
            const funcName = op.selector;
            sortProxy[funcName](op.desc ? SortDirection.DESCENDING : SortDirection.ASCENDING)
        });
    }
    return sortProxy;
};

export const mapFilters = (c, filterOps) => {
    let filtersList = [];
    // to filter by tourId
    if (filterOps?.tourId) {
        //console.log(filterOps.tourId)
        filtersList.push(c.tourId.eq(filterOps.tourId));
    }
    return filtersList;
}

export const applyDataStoreFiltering = (c, filterOps) => {
    if (filterOps) {
        return c.and(c => mapFilters(c,filterOps));
    }
}
