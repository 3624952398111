import React, {useState, useCallback} from "react";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import QrCodeScannerPopup from "./QrCodeScannerPopup/QrCodeScannerPopup";

export default function QrCodeScannerTextBox({ cell }) {
    const [value, setValue] = useState(cell.value);
    const [showQrCodeScanner, setShowQrCodeScanner] = useState(false);

    const deviceIdButton = {
        icon: 'photo',
        type: 'text',
        onClick: () => {
            setShowQrCodeScanner(true);
        },
    };

    const qrScannerSuccessCallback = (decodedText, decodedResult) => {
        if (decodedText) {
            setValue(decodedText);
        }
        setShowQrCodeScanner(false);
    }

    return (
        <>
            {showQrCodeScanner ?
                <QrCodeScannerPopup
                    callback={qrScannerSuccessCallback}
                    onHiding={() => setShowQrCodeScanner(false)}/>
                :
                <TextBox
                    placeholder="ID"
                    value={value}
                    onValueChanged={(e) => {
                        cell.setValue(e.value);
                        setValue(e.value)
                    }}
                >
                    <TextBoxButton
                        name="deviceIdButton"
                        location="after"
                        options={deviceIdButton}
                    />
                </TextBox>
            }
        </>
    );
}
