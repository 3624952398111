import React, {useState, Suspense, useEffect} from "react";
import './App.css';
import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.material.blue.light.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import {AuthProvider} from "./contexts/AuthContext";
import {OnlineStatusProvider} from "./contexts/OnlineStatusContext";
import {useRoutes, navigate} from 'hookrouter';
import {Amplify, API, Auth, Hub, DataStore} from 'aws-amplify';
//import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import AuthenticatorWithUsername from "./components/auth/AuthenticatorWithUsername";
//import {User} from "./models";
import NavigationDrawer from "./components/menu/NavigationDrawer/NavigationDrawer";
import {useTranslation} from "react-i18next";
import FallbackLoader from "./components/FallbackLoader";
import {AdminMenuItems, DefaultMenuItems, NavbarMenuItems, TechnicianMenuItems} from "./menuitems";
import {userByEmail} from "./graphql/queries";

import UserManagementView from "./components/pages/UserManagementView";
import WatermeterImport from "./components/pages/WatermeterImport";
import CitizenImport from "./components/pages/CitizenImport";
import Dashboard from "./components/pages/Dashboard";
import CustomerManagementView from "./components/pages/CustomerManagementView";
import DeviceManagementView from "./components/pages/DeviceManagementView";
import StockLocationManagementView from "./components/pages/StockLocationManagementView";
import PeriodManagementView from "./components/pages/PeriodManagementView";
import TourManagementView from "./components/pages/TourManagementView";
import TourTaskManagementView from "./components/pages/TourTaskManagementView";
import TourTechnicianView from "./components/pages/TourTechnicianView";
import TourTaskTechnicianView from "./components/pages/TourTaskTechnicianView";
import MeteringPointManagementView from "./components/pages/MeteringPointManagementView";

Amplify.configure(awsconfig);
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

const Routes = {
  "/": () => <UserManagementView/>,
  "/users": () => <UserManagementView/>,
  "/watermeters": () => <DeviceManagementView/>,
  "/watermeter-import": () => <WatermeterImport/>,
  "/warehouse": () => <StockLocationManagementView/>,
  "/device-swap": () => <PeriodManagementView periodType={"DEVICE_CHANGE"}/>,
  "/device-swap/:periodId/tours": ({periodId}) => <TourManagementView periodId={periodId}/>,
  "/device-swap/:periodId/:tourId/tasks": ({periodId, tourId}) => <TourTaskManagementView periodId={periodId} tourId={tourId}/>,
  "/device-reading": () => <PeriodManagementView periodType={"DEVICE_READING"}/>,
  "/device-reading/:periodId/tours":  ({periodId}) => <TourManagementView periodId={periodId}/>,
  "/device-reading/:periodId/:tourId/tasks": ({periodId, tourId}) => <TourTaskManagementView periodId={periodId} tourId={tourId}/>,
  "/citizens": () => <CustomerManagementView/>,
  "/citizen-import": () => <CitizenImport/>,
  "/dashboard": () => <Dashboard/>,
  "/meterpoints": () => <MeteringPointManagementView/>,
  //"/qr": () => <QrCodeScannerPopup callback={(decodedText, decodedResult) => {console.log(decodedText, decodedResult);}}/>,
  //"/camera": () => <CameraTest/>,
  "/tech-dev-swap": () => <TourTechnicianView/>,
  "/tech-dev-swap/:periodId/:tourId/tasks": ({periodId, tourId}) => <TourTaskTechnicianView periodId={periodId} tourId={tourId}/>,
};

function App() {
  loadMessages(deMessages);
  locale(navigator.language);
  const routeResult = useRoutes(Routes);
  const [t] = useTranslation();
  const [user, setUser] = useState({auth:null, userInfoFromDB:null});
  const [menuItems, setMenuItems] = useState(null);

    const fetchUserFromDB = async () => {
        try {
            const auth = await Auth.currentUserInfo();
            const email = auth?.attributes?.email;
            if (email) {
                let {data: {UserByEmail: {items: u}}} = await API.graphql({
                    query: userByEmail,
                    variables: {email: email},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                // filter as deleted marked results
                u = u.filter((item) => item._deleted !== true)
                const userInfoFromDB = u?.length === 1 ? u[0] : null;
                setUser({auth, userInfoFromDB});
                // adapt the menu for different user roles
                switch (userInfoFromDB?.role) {
                    case "SuperAdmin":
                    case "Admin":
                        setMenuItems(AdminMenuItems);
                        break;
                    case "Technician":
                        //navigate("/device-swap")
                        setMenuItems(TechnicianMenuItems);
                        break;
                    default:
                        navigate("/dashboard");
                        setMenuItems(DefaultMenuItems);
                        break;
                }
                return userInfoFromDB;
            } else {
                setUser({auth: null, userInfoFromDB: null});
                setMenuItems(null);
            }
        } catch(error) {
            console.error(error);
        }
    }

  useEffect(() => {
      const hubListenerAuth = async (data) => {
          if (data.payload.event === 'signOut') {
              await DataStore.clear();
              await DataStore.stop();
          }
          if (data.payload.event === 'signIn') {
              await DataStore.start();
              fetchUserFromDB();
          }
      };
      Hub.listen('auth', hubListenerAuth);
      fetchUserFromDB(); // check manually the first time because we won't get a Hub event
      return () => Hub.remove('auth', hubListenerAuth);
  }, []);

  return (
      <I18nextProvider i18n={i18n}>
        <AuthProvider value={user}>
            <OnlineStatusProvider>
              <NavigationDrawer isPublic={!user?.auth} menuItems={menuItems} navbarItems={NavbarMenuItems} signOut={() => {navigate("/"); Auth.signOut().then(setUser({auth:null, userInfoFromDB:null}))}}>
                <div className="App">
                  <Suspense fallback={
                    <FallbackLoader />
                  }>
                  <AuthenticatorWithUsername loginMechanisms={['username']} hideSignUp={true}>
                      {routeResult || <div className="PageNotFound"><h1>{t('404.title')}</h1><p>{t('404.text')}</p></div>}
                  </AuthenticatorWithUsername>
                  </Suspense>
                </div>
              </NavigationDrawer>
            </OnlineStatusProvider>
        </AuthProvider>
      </I18nextProvider>
    );
}

export default App;