import { Amplify} from 'aws-amplify';
import {Authenticator, Heading, Image, View, translations} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';
import awsExports from '../../aws-exports';
import inwasoLogo from "../../images/Inwaso_Logo.svg";

I18n.putVocabularies(translations);
Amplify.configure(awsExports);

function AuthenticatorWithUsername({children, services, lang}) {

    I18n.setLanguage(lang ? lang : 'de');

    const components = {
        Header() {

            return (
                <View textAlign="center">
                    <Image
                        alt="Inwaso Logo"
                        src={inwasoLogo}
                    />
                </View>
            );
        },

        SignIn: {
            Header() {
                return null;
            }
        },
        ResetPassword: {
            Header() {
                return (
                    <Heading
                        level={3}
                        font={"var(--mp-font-sans-serif)"}
                        color={"var(--mp-primary-color)"}
                    >
                        Passwort zurücksetzen
                    </Heading>
                );
            }
        },
    };

    const formFields = {
        signIn: {
            username: {
                labelHidden: false,
                //placeholder: 'Enter your email',
            },
        },
        signUp: {
            password: {
                labelHidden: false,
                //label: 'Password:',
                //placeholder: 'Enter your Password:',
                isRequired: false,
                order: 2,
            },
            confirm_password: {
                labelHidden: false,
                //label: 'Confirm Password:',
                order: 1,
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: false,
                //placeholder: 'Enter your Password:',
            },
        },
        resetPassword: {
            username: {
                labelHidden: false,
                //placeholder: 'Enter your email:',
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: false,
                //placeholder: 'Enter your Confirmation Code:',
                //label: 'New Label',
                isRequired: false,
            },
            confirm_password: {
                labelHidden: false,
                //placeholder: 'Enter your Password Please:',
            },
        },
        confirmSignIn: {
            confirmation_code: {
                labelHidden: false,
                //label: 'New Label',
                //placeholder: 'Enter your Confirmation Code:',
                isRequired: false,
            },
        },
    };

    return (
        <Authenticator formFields={formFields} components={components} services={services} hideSignUp={true}>
            {children}
        </Authenticator>
    );
}

export default AuthenticatorWithUsername;