// contains independent and portable CustomDataStore functions
import { DataStore, Storage, Cache } from "aws-amplify";
import { Image } from "../models";
import notify from "devextreme/ui/notify";
import awsmobile from '../aws-exports';

// setup for cache
const config = {
    capacityInBytes: 5000000,
    itemMaxSize: 5000000, // bytes
    storage: window.localStorage
    // ...
};
Cache.configure(config);

const imagesListKey = "images-list";

const removeImageFromCache = (id) => {
    // remove image from cache
    let latestImagesList = Cache.getItem(imagesListKey);
    latestImagesList = latestImagesList ? latestImagesList : [];
    let index = latestImagesList.findIndex((elem) => elem.id === id);
    if (index > -1) {
        latestImagesList.splice(index,1);
        Cache.setItem(imagesListKey, latestImagesList);
    }
}

const addImageToCache = (id, imgName, imgContent, imgDescription, linkId) => {
    let imagesList = Cache.getItem(imagesListKey);
    imagesList = imagesList ? imagesList : [];
    // check if already existing
    let index = imagesList.findIndex((elem) => elem.id === id);
    if (index > -1) {
        return;
    }
    imagesList = imagesList.concat({
        id:id,
        linkId: linkId,
        name: imgName,
        description: imgDescription,
        fileContent: imgContent
    });
    Cache.setItem(imagesListKey, imagesList);
}

export const uploadImage = (id, imgName, imgContent, imgDescription, linkId) => {
    return Storage.put(imgName, imgContent, {
        resumable: false
        /*progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },*/
    })
        .then((resp) => { // on success save image info in DDB
            DataStore.save(
                new Image({
                    id: id,
                    linkId: linkId,
                    name: imgName,
                    description: imgDescription,
                    file: {
                        bucket: awsmobile.aws_user_files_s3_bucket,
                        key: resp.key,
                        region: awsmobile.aws_user_files_s3_bucket_region
                    },
                    createdAt: dateToAWSDateTime(new Date()),
                    updatedAt: dateToAWSDateTime(new Date()),
                    groupRead: ["Admin", "Technician"],
                    groupWrite: ["Admin"]
                })
            ).then((success) => {
                notify(`Bild ${imgName} wurde erfolgreich hochgeladen und gespeichert`, "success", 3000);
                removeImageFromCache(id);
            }
        ).
            catch((error) => {  // on error save image info in Cache for later retry
                console.error(error);
                notify(`Bild ${imgName} wurde hochgeladen aber nicht gespeichert. `, "error", 3000);
            })
        }).catch((error) => {  // on error save image info in Cache for later retry
            console.log('Error while uploading (maybe you are offline?): ', error);
            addImageToCache(id, imgName, imgContent, imgDescription, linkId);
            notify(`Das Bild ${imgName} konnte nicht hochgeladen werden. Es wurde lokal gespeichert.`, "warning", 3000);
        });
}

export const uploadCachedImages = () => {
    let imagesList = Cache.getItem(imagesListKey);
    imagesList = imagesList ? imagesList : [];
    imagesList.forEach(async (img) => {
        await uploadImage (img.id, img.name, img.fileContent, img.description, img.linkId)
    });
}

// parses the DataGrid selection filter to a Datastore understandable format
export const applySelectedFiltering = (c, filterOps, negation= false) => {
    if (filterOps !== null && filterOps?.length > 0) {
        const firstElement = filterOps.shift();
        if (filterOps[0] === "or" || filterOps[0] === "and") { // or/and operations
            return c[filterOps[0]](c => [
                applySelectedFiltering(c, firstElement, negation),
                applySelectedFiltering(c, filterOps.slice(1), negation)
            ]);
        } else if (Array.isArray(firstElement)) { // to unwrap array in array
            return applySelectedFiltering(c, firstElement, negation);
        } else if (firstElement === "!") { // not operations
            return applySelectedFiltering(c, filterOps, true);
        } else { // basic operation
            if (negation === true) {
                return c[firstElement]["ne"](filterOps[1]);
            } else {
                return c[firstElement]["eq"](filterOps[1]);
            }
        }
    }
}

export const dateToAWSDate = (date) => {
    return date ? (new Date(date).toISOString().slice(0, 10)) : null;
}

export const dateToAWSDateTime = (date) => {
    return date ? (new Date(date)).toISOString() : null;
}