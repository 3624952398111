import '../../App.css';
import React from "react";
import Camera, { FACING_MODES, IMAGE_TYPES }  from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Popup } from 'devextreme-react/popup';

function CameraPopup({onTakePhoto, onCameraError, onHiding}) {

    return (
        <div className={"QrCodeScannerPopup"}>
            <Popup
                visible={true}
                onHiding={onHiding}
                fullScreen={true}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                contentRender={ () =>
                    <Camera
                        onTakePhoto = { (dataUri) => onTakePhoto(dataUri) }
                        //onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                        onCameraError = { (error) => onCameraError(error) }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        //idealResolution = {{width: window.innerWidth, height: window.innerHeight}}
                        imageType = {IMAGE_TYPES.JPG}
                        imageCompression = {0.97}
                        isMaxResolution = {true}
                        isImageMirror = {false}
                        isSilentMode = {false}
                        //isDisplayStartCameraError = {true}
                        isFullscreen = {false}
                        //sizeFactor = {0.75}
                        //onCameraStart = { (stream) => { handleCameraStart(stream); } }
                        //onCameraStop = { () => { handleCameraStop(); } }
                    />
                }
            />
        </div>
    );
}

export default CameraPopup;
