import '../../App.css';
import React, {useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import {getTBToken} from "../../graphql/queries";
import AuthContext from "../../contexts/AuthContext";
import notify from "devextreme/ui/notify";
import { LoadPanel } from 'devextreme-react/load-panel';
import {UserRole} from "../../models";
import {useTranslation} from "react-i18next";
import Button from "../Button";

function Dashboard() {
    const [t] = useTranslation();
    const userContext = useContext(AuthContext);
    const [tbTokens, setTBTokens] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userContext.userInfoFromDB) {
            setLoading(true);
            API.graphql({
                query: getTBToken,
                variables: {userId: userContext.userInfoFromDB?.tbUserId}
            }).then(data => {
                const {data: {getTBToken: {embedUrl, token, refreshToken}}} = data;
                if (embedUrl && token) { // token found
                    setTBTokens({embedUrl, token, refreshToken});
                    window.open(embedUrl, '_blank');
                    notify(t('dashboard.msg_success1'), "success", 3000);
                } else {
                    setTBTokens(null);
                    notify(t('dashboard.msg_error1'), "error", 3000);
                }
                setLoading(false);
            }).catch(error => {
                setTBTokens(null);
                setLoading(false);
                notify(t('dashboard.msg_error1'), "error", 3000);
                console.error(`Error on fetching Token: ${JSON.stringify(error)}`);
            });
        }
    }, [userContext.userInfoFromDB]);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN, UserRole.TECHNICIAN, UserRole.CITIZEN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="Dashboard">
                {(tbTokens) ?
                    <div className={"centerHor"}>
                        <p>{t('dashboard.msg1')}</p>
                        <Button centered={true} text={t('dashboard.btn_text1')} onClick={() => window.open(tbTokens?.embedUrl, '_blank')} icon={"chart"}/>
                    </div>
                    :
                    null
                }
                <LoadPanel
                    visible={loading}
                    message={t('dashboard.msg_loadpanel')}
                    showIndicator={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
            </div>
        );
    } else {
        return null;
    }
}

export default Dashboard;
