import './NavBar.css';
import React from "react";
import {A} from "hookrouter";

function NavBar({menuItems}) {

    return (
        <div className={"NavBar"}>
            <ul>
                {menuItems?.map((item) => {
                    return <li  key={item.name}>
                        {(item.link ===true ?
                                <a href={item.path}>{item.name}</a>
                                :
                                <A href={item.path}>{item.name}</A>
                        )}
                    </li>
                })}
            </ul>
        </div>
    )
}
export default NavBar;