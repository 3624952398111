/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTBToken = /* GraphQL */ `
  query GetTBToken($userId: ID!) {
    getTBToken(userId: $userId) {
      token
      refreshToken
      embedUrl
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      role
      status
      cogUserPoolId
      cogUserName
      tbUserId
      tbEntityType
      assignedTours {
        items {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      accomplishedTourTasks {
        items {
          id
          tourId
          buchNrLfd
          meteringPointId
          type
          date
          comment
          accomplishedAt
          accomplishedUserId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      owner
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByStatus = /* GraphQL */ `
  query UserByStatus(
    $status: UserStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByRole = /* GraphQL */ `
  query UserByRole(
    $cogUserPoolId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByRole(
      cogUserPoolId: $cogUserPoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByCognitoUserName = /* GraphQL */ `
  query UserByCognitoUserName(
    $cogUserName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCognitoUserName(
      cogUserName: $cogUserName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByThingsboardUserId = /* GraphQL */ `
  query UserByThingsboardUserId(
    $tbUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByThingsboardUserId(
      tbUserId: $tbUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        assignedTours {
          nextToken
          startedAt
        }
        accomplishedTourTasks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStockLocation = /* GraphQL */ `
  query GetStockLocation($id: ID!) {
    getStockLocation(id: $id) {
      id
      name
      address {
        street
        streetNr
        zipCode
        city
        country
      }
      comment
      devices {
        items {
          id
          deviceId
          tbId
          serialNr
          pulseWeight
          type
          meterIndexI
          meterIndexPulses
          status
          productionDate
          producer
          temporaryPlacement
          dN
          articleNo
          devEUI
          article
          articleType
          dmatDelivery
          loRa
          inactivityAlarmTime
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          stockLocationDevicesId
        }
        nextToken
        startedAt
      }
      images {
        items {
          id
          linkId
          name
          description
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStockLocations = /* GraphQL */ `
  query ListStockLocations(
    $id: ID
    $filter: ModelStockLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStockLocations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        comment
        devices {
          nextToken
          startedAt
        }
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStockLocations = /* GraphQL */ `
  query SyncStockLocations(
    $filter: ModelStockLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStockLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        comment
        devices {
          nextToken
          startedAt
        }
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerId
      tbId
      firstName
      lastName
      gender
      phone
      email
      address {
        street
        streetNr
        zipCode
        city
        country
      }
      objects {
        items {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      meteringPoints {
        items {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $id: ID
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByCustomerId = /* GraphQL */ `
  query CustomerByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByTbId = /* GraphQL */ `
  query CustomerByTbId(
    $tbId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByTbId(
      tbId: $tbId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getObject = /* GraphQL */ `
  query GetObject($id: ID!) {
    getObject(id: $id) {
      id
      objectId
      name
      customerId
      customer {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      meteringPoints {
        items {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        nextToken
        startedAt
      }
      tbId
      geoLocation {
        longitude
        latitude
        altitude
      }
      address {
        street
        streetNr
        zipCode
        city
        country
      }
      images {
        items {
          id
          linkId
          name
          description
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects(
    $id: ID
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listObjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncObjects = /* GraphQL */ `
  query SyncObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncObjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const objectByObjectId = /* GraphQL */ `
  query ObjectByObjectId(
    $objectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByObjectId(
      objectId: $objectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const objectByCustomerId = /* GraphQL */ `
  query ObjectByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const objectByTbId = /* GraphQL */ `
  query ObjectByTbId(
    $tbId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    objectByTbId(
      tbId: $tbId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeteringPoint = /* GraphQL */ `
  query GetMeteringPoint($id: ID!) {
    getMeteringPoint(id: $id) {
      id
      edvObjAbgId
      abgabeNr
      abgabeLfdNr
      betriebsNr
      bauJahr
      letztesEichJahr
      eichIntervall
      anzahlStellen
      fabrikat
      einbauDatum
      wasserGenossenschaft
      versorgungsKreis
      anschlussDatum
      buchNr
      buchNrLfd
      durchFlussGroesse
      zaehlerArt
      anfangsStand
      anfangsStandDatum
      endStand
      endStandDatum
      webAblesung
      zlrVerbrLetztPer
      deviceId
      objectId
      object {
        id
        objectId
        name
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        tbId
        geoLocation {
          longitude
          latitude
          altitude
        }
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        images {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      customerId
      customer {
        id
        customerId
        tbId
        firstName
        lastName
        gender
        phone
        email
        address {
          street
          streetNr
          zipCode
          city
          country
        }
        objects {
          nextToken
          startedAt
        }
        meteringPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      images {
        items {
          id
          linkId
          name
          description
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      periods {
        items {
          id
          meteringPointID
          periodID
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tasks {
        items {
          id
          tourId
          buchNrLfd
          meteringPointId
          type
          date
          comment
          accomplishedAt
          accomplishedUserId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tbId
      status
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
      objectMeteringPointsId
    }
  }
`;
export const listMeteringPoints = /* GraphQL */ `
  query ListMeteringPoints(
    $id: ID
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeteringPoints(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeteringPoints = /* GraphQL */ `
  query SyncMeteringPoints(
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeteringPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByEdvObjAbgId = /* GraphQL */ `
  query MeteringPointByEdvObjAbgId(
    $edvObjAbgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByEdvObjAbgId(
      edvObjAbgId: $edvObjAbgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByAbgabeNr = /* GraphQL */ `
  query MeteringPointByAbgabeNr(
    $abgabeNr: ID!
    $abgabeLfdNr: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByAbgabeNr(
      abgabeNr: $abgabeNr
      abgabeLfdNr: $abgabeLfdNr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByBetriebsNr = /* GraphQL */ `
  query MeteringPointByBetriebsNr(
    $betriebsNr: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByBetriebsNr(
      betriebsNr: $betriebsNr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByLetztesEichJahr = /* GraphQL */ `
  query MeteringPointByLetztesEichJahr(
    $letztesEichJahr: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByLetztesEichJahr(
      letztesEichJahr: $letztesEichJahr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByBuchNr = /* GraphQL */ `
  query MeteringPointByBuchNr(
    $buchNr: Int!
    $buchNrLfd: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByBuchNr(
      buchNr: $buchNr
      buchNrLfd: $buchNrLfd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByEndStandDatum = /* GraphQL */ `
  query MeteringPointByEndStandDatum(
    $endStandDatum: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByEndStandDatum(
      endStandDatum: $endStandDatum
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByDeviceId = /* GraphQL */ `
  query MeteringPointByDeviceId(
    $deviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByDeviceId(
      deviceId: $deviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByObjectId = /* GraphQL */ `
  query MeteringPointByObjectId(
    $objectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByObjectId(
      objectId: $objectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const meteringPointByCustomerId = /* GraphQL */ `
  query MeteringPointByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    meteringPointByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const assetByTbId = /* GraphQL */ `
  query AssetByTbId(
    $tbId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetByTbId(
      tbId: $tbId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPeriodMeteringPoint = /* GraphQL */ `
  query GetPeriodMeteringPoint($id: ID!) {
    getPeriodMeteringPoint(id: $id) {
      id
      meteringPointID
      periodID
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPeriodMeteringPoints = /* GraphQL */ `
  query ListPeriodMeteringPoints(
    $id: ID
    $filter: ModelPeriodMeteringPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPeriodMeteringPoints(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        meteringPointID
        periodID
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPeriodMeteringPoints = /* GraphQL */ `
  query SyncPeriodMeteringPoints(
    $filter: ModelPeriodMeteringPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPeriodMeteringPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        meteringPointID
        periodID
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byMeteringPoint = /* GraphQL */ `
  query ByMeteringPoint(
    $meteringPointID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPeriodMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byMeteringPoint(
      meteringPointID: $meteringPointID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meteringPointID
        periodID
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byPeriod = /* GraphQL */ `
  query ByPeriod(
    $periodID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPeriodMeteringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPeriod(
      periodID: $periodID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meteringPointID
        periodID
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      deviceId
      tbId
      serialNr
      pulseWeight
      type
      meterIndexI
      meterIndexPulses
      status
      productionDate
      producer
      temporaryPlacement
      dN
      articleNo
      devEUI
      article
      articleType
      dmatDelivery
      loRa
      inactivityAlarmTime
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
      stockLocationDevicesId
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $id: ID
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceByDeviceId = /* GraphQL */ `
  query DeviceByDeviceId(
    $deviceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByDeviceId(
      deviceId: $deviceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceByTbId = /* GraphQL */ `
  query DeviceByTbId(
    $tbId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByTbId(
      tbId: $tbId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceByType = /* GraphQL */ `
  query DeviceByType(
    $type: DeviceType!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const deviceByStatus = /* GraphQL */ `
  query DeviceByStatus(
    $status: DeviceStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deviceByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        tbId
        serialNr
        pulseWeight
        type
        meterIndexI
        meterIndexPulses
        status
        productionDate
        producer
        temporaryPlacement
        dN
        articleNo
        devEUI
        article
        articleType
        dmatDelivery
        loRa
        inactivityAlarmTime
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        stockLocationDevicesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPeriod = /* GraphQL */ `
  query GetPeriod($id: ID!) {
    getPeriod(id: $id) {
      id
      type
      year
      comment
      meteringPoints {
        items {
          id
          meteringPointID
          periodID
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      tours {
        items {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPeriods = /* GraphQL */ `
  query ListPeriods(
    $id: ID
    $filter: ModelPeriodFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPeriods(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        year
        comment
        meteringPoints {
          nextToken
          startedAt
        }
        tours {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPeriods = /* GraphQL */ `
  query SyncPeriods(
    $filter: ModelPeriodFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPeriods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        year
        comment
        meteringPoints {
          nextToken
          startedAt
        }
        tours {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const periodsByYear = /* GraphQL */ `
  query PeriodsByYear(
    $year: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    periodsByYear(
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        year
        comment
        meteringPoints {
          nextToken
          startedAt
        }
        tours {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTour = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      id
      buchNr
      periodId
      period {
        id
        type
        year
        comment
        meteringPoints {
          nextToken
          startedAt
        }
        tours {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      tasks {
        items {
          id
          tourId
          buchNrLfd
          meteringPointId
          type
          date
          comment
          accomplishedAt
          accomplishedUserId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      date
      comment
      assignedToUserId
      accomplishedAt
      status
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTours = /* GraphQL */ `
  query ListTours(
    $id: ID
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTours(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTours = /* GraphQL */ `
  query SyncTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTours(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourByBuchNr = /* GraphQL */ `
  query TourByBuchNr(
    $buchNr: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourByBuchNr(
      buchNr: $buchNr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourByPeriodId = /* GraphQL */ `
  query TourByPeriodId(
    $periodId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourByPeriodId(
      periodId: $periodId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourByUser = /* GraphQL */ `
  query TourByUser(
    $assignedToUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourByUser(
      assignedToUserId: $assignedToUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTourTask = /* GraphQL */ `
  query GetTourTask($id: ID!) {
    getTourTask(id: $id) {
      id
      tourId
      tour {
        id
        buchNr
        periodId
        period {
          id
          type
          year
          comment
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        tasks {
          nextToken
          startedAt
        }
        date
        comment
        assignedToUserId
        accomplishedAt
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      buchNrLfd
      meteringPointId
      meteringPoint {
        id
        edvObjAbgId
        abgabeNr
        abgabeLfdNr
        betriebsNr
        bauJahr
        letztesEichJahr
        eichIntervall
        anzahlStellen
        fabrikat
        einbauDatum
        wasserGenossenschaft
        versorgungsKreis
        anschlussDatum
        buchNr
        buchNrLfd
        durchFlussGroesse
        zaehlerArt
        anfangsStand
        anfangsStandDatum
        endStand
        endStandDatum
        webAblesung
        zlrVerbrLetztPer
        deviceId
        objectId
        object {
          id
          objectId
          name
          customerId
          tbId
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        customerId
        customer {
          id
          customerId
          tbId
          firstName
          lastName
          gender
          phone
          email
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        images {
          nextToken
          startedAt
        }
        periods {
          nextToken
          startedAt
        }
        tasks {
          nextToken
          startedAt
        }
        tbId
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
        objectMeteringPointsId
      }
      type
      date
      comment
      accomplishedAt
      accomplishedUserId
      images {
        items {
          id
          linkId
          name
          description
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTourTasks = /* GraphQL */ `
  query ListTourTasks(
    $id: ID
    $filter: ModelTourTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTourTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tourId
        tour {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        buchNrLfd
        meteringPointId
        meteringPoint {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        type
        date
        comment
        accomplishedAt
        accomplishedUserId
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTourTasks = /* GraphQL */ `
  query SyncTourTasks(
    $filter: ModelTourTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTourTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tourId
        tour {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        buchNrLfd
        meteringPointId
        meteringPoint {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        type
        date
        comment
        accomplishedAt
        accomplishedUserId
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourTaskByTourId = /* GraphQL */ `
  query TourTaskByTourId(
    $tourId: ID!
    $buchNrLfd: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTourTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourTaskByTourId(
      tourId: $tourId
      buchNrLfd: $buchNrLfd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tourId
        tour {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        buchNrLfd
        meteringPointId
        meteringPoint {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        type
        date
        comment
        accomplishedAt
        accomplishedUserId
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourTaskByMeteringPointId = /* GraphQL */ `
  query TourTaskByMeteringPointId(
    $meteringPointId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTourTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourTaskByMeteringPointId(
      meteringPointId: $meteringPointId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tourId
        tour {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        buchNrLfd
        meteringPointId
        meteringPoint {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        type
        date
        comment
        accomplishedAt
        accomplishedUserId
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tourTaskByAccomplishedUserId = /* GraphQL */ `
  query TourTaskByAccomplishedUserId(
    $accomplishedUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTourTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tourTaskByAccomplishedUserId(
      accomplishedUserId: $accomplishedUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tourId
        tour {
          id
          buchNr
          periodId
          date
          comment
          assignedToUserId
          accomplishedAt
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
        }
        buchNrLfd
        meteringPointId
        meteringPoint {
          id
          edvObjAbgId
          abgabeNr
          abgabeLfdNr
          betriebsNr
          bauJahr
          letztesEichJahr
          eichIntervall
          anzahlStellen
          fabrikat
          einbauDatum
          wasserGenossenschaft
          versorgungsKreis
          anschlussDatum
          buchNr
          buchNrLfd
          durchFlussGroesse
          zaehlerArt
          anfangsStand
          anfangsStandDatum
          endStand
          endStandDatum
          webAblesung
          zlrVerbrLetztPer
          deviceId
          objectId
          customerId
          tbId
          status
          createdAt
          updatedAt
          groupRead
          groupWrite
          _version
          _deleted
          _lastChangedAt
          objectMeteringPointsId
        }
        type
        date
        comment
        accomplishedAt
        accomplishedUserId
        images {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      linkId
      name
      description
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $id: ID
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listImages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        linkId
        name
        description
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        linkId
        name
        description
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const linkId = /* GraphQL */ `
  query LinkId(
    $linkId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkId(
      linkId: $linkId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        linkId
        name
        description
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
