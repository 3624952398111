import CustomStore from 'devextreme/data/custom_store';
import { DataStore } from "aws-amplify";
import { StockLocation, StockLocationStatus, UserRole } from "../models";
import { v4 as uuidv4 } from 'uuid';
import notify from "devextreme/ui/notify";
import {applySelectedFiltering, uploadImage} from "../common/customDataStore";

const mapStockLocationData = async (stockLocation) => {
    let {
        id,
        name,
        address,
        comment,
        devices,
        images,
        status,
        createdAt,
        updatedAt,
    } = stockLocation;
    if (devices) {
        devices = await devices.toArray();
    }
    if (images) {
        images = await images.toArray();
        //images = await Promise.all(images.map((t) => mapImageData(t)));
    }
    return {
        id,
        name,
        address,
        comment,
        devices,
        images,
        status,
        createdAt,
        updatedAt
    };
};

export const stockLocationsStore = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        const {skip, take, filter, isLoadingAll} = loadOptions;
        let stockLocations = [];
        if (isLoadingAll === true) { // special flag from DataGrid, e.g. for export
            stockLocations = await DataStore.query(StockLocation, c => c.status.neq(StockLocationStatus.REMOVED));
            // map stockLocations
            stockLocations = await Promise.all(stockLocations.map((stockLocation) => mapStockLocationData(stockLocation)));
        } else if (Array.isArray(filter)) { // handle filter for selected rows
            stockLocations = await DataStore.query(StockLocation,
                c => applySelectedFiltering(c,filter));
            stockLocations = await Promise.all(stockLocations.map((ret) => mapStockLocationData(ret)));
        } else { // paging
            const page = Math.floor(skip/take);
            stockLocations = await DataStore.query(StockLocation,
                c => c.status.ne(StockLocationStatus.REMOVED), {
                    page: page,
                    limit: take ? take : 50,
                    //sort: s => applySorting(s, sort)
                } );
            // map stockLocations
            stockLocations = await Promise.all(stockLocations.map((stockLocation) => mapStockLocationData(stockLocation)));
        }
        return {
            data: stockLocations
        }
    },
    remove: (key) => {
        return DataStore.query(StockLocation, key)
            .then((original) => {
                DataStore.save(
                    StockLocation.copyOf(original, (updated) => {
                        updated["status"] = StockLocationStatus.REMOVED;
                    }).then(() => notify(`Lagerort ${original.name}  wurde erfolgreich aktualisiert`, "success", 3000))
                        .catch((error) => {
                            notify(`Lagerort ${original.name}  wurde nicht aktualisiert`, "error", 3000);
                            throw Error(error);
                        })
                )}
            )
            .catch((error) => {
                notify(`Kein Lagerort mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    insert: (data) => {
        const id = uuidv4();
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from StockLocation: ${data.name}`,
                        id);
                }
            })
        }
        return DataStore.save(
            new StockLocation({
                id: id,
                name: data.firstName,
                address: data.address,
                comment: data.comment,
                status: data.status,
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                groupRead: [UserRole.ADMIN, UserRole.TECHNICIAN],
                groupWrite: [UserRole.ADMIN],
            })
        ).then(() => notify(`Lagerort wurde erfolgreich angelegt`, "success", 3000))
        .catch((error) => {
            notify(`Lagerort wurde nicht angelegt`, "error", 3000);
            throw Error(error);
        })
    },
    update: (key, data) => {
        if (data.images) {
            // upload images first
            data.images.forEach((img) => {
                if (img.isNew === true) {
                    uploadImage(uuidv4(), img.name,
                        img.content,
                        `Image from StockLocation: ${data.name}`,
                        key);
                }
            })
        }
        return DataStore.query(StockLocation, key)
            .then((original) => DataStore.save(
                StockLocation.copyOf(original, (updated) => {
                    for(const [key, value] of Object.entries(data)) {
                        updated[key] = value;
                    }
                })).then(() => notify(`Lagerort ${original.name}  wurde erfolgreich aktualisiert`, "success", 3000))
                .catch((error) => {
                    notify(`Lagerort ${original.name}  wurde nicht aktualisiert`, "error", 3000);
                    throw Error(error);
                })
            )
            .catch((error) => {
                notify(`Kein Lagerort mit dieser ID gefunden`, "error", 3000);
                throw Error(error);
            });
    },
    byKey: (key) => {
        return DataStore.query(StockLocation, key)
            .then(async (original) => await mapStockLocationData(original))
            .catch((error) => {
                notify(`Kein Lagerort mit ID ${key} gefunden`, "error", 3000);
                throw Error(error);
            });
    }
});

export async function handleSubscriptionEvent(data) {
    try {
        const elem = data.element;
        const transformed_data = mapStockLocationData(elem);
        switch(data.opType) {
            case "UPDATE":
                stockLocationsStore.push([{
                    type: "update",
                    data: transformed_data,
                    key: elem.id}]);
                break;
            case "INSERT":
                stockLocationsStore.push([{
                    type: "insert",
                    data: transformed_data}]);
                break;
            case "DELETE":
                stockLocationsStore.push([{ type: "remove", key: elem.id }]);
                break;
            default: break;
        }
    } catch (err) {
        console.error(`Error on handleSubscriptionEvent: ${JSON.stringify(err)}`);
    }
}