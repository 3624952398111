import '../../App.css';
import React from "react";
//import ScrollView from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import QrCodeScanner from "../QrCodeScanner/QrCodeScanner";
//import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

const scannerErrorCallBack = (error) => {
    console.warn("[CUSTOM]: scannerErrorCallBack error: ", error);
    notify("Fehler beim QR Code Scan", "error", 3000);
}

function QrCodeScannerPopup({callback, onHiding}) {

    //const [showScanner, setShowScanner] = useState(true);

    const scannerSuccessCallBack = (decodedText, decodedResult) => {
        callback(decodedText, decodedResult);
        /*let result = confirm(decodedText, "Bestätigen");
        result.then((dialogResult) => {
            if (dialogResult) {
                console.log("[CUSTOM]: scannerSuccessCallBack confirm clicked: ", decodedText);
                callback(decodedText, decodedResult);
            } else {
                console.log("here")
                setShowScanner(true);
            }
        });*/
    }

    return (
        <div className={"QrCodeScannerPopup"}>
            <Popup
                visible={true}
                onHiding={onHiding}
                fullScreen={true}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                contentRender={ () =>
                        <QrCodeScanner
                            qrCodeSuccessCallback={scannerSuccessCallBack}
                            qrCodeErrorCallback={scannerErrorCallBack}
                            qrbox={{
                                width: 300,
                                height: 300
                            }}
                            aspectRatio={window.innerWidth/window.innerHeight}
                        />
                }
            />
        </div>
    );
}

export default QrCodeScannerPopup;
