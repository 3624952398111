import '../../App.css';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ScrollView from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import {meteringPointsStore} from '../../data/meteringPointsStore';
import {periodsStore} from '../../data/periodsStore';
import Form, { Item, GroupItem, Label, RequiredRule, SimpleItem, EmptyItem, ButtonItem } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import List from 'devextreme-react/list';
import TextArea from "devextreme/ui/text_area";
import notify from "devextreme/ui/notify";


function AddPeriodFormPopup({onHiding, periodType}) {
    const [t] = useTranslation();
    const [formData, setFormData] = useState({
        year: new Date().getFullYear(),
        status: 'CREATED',
        selectedmeteringPoints:[]
    });

    const [filterDate, setFilterDate] = useState(periodType === "DEVICE_CHANGE" ? new Date().getFullYear()-5 : new Date());
    const [meteringPoints, setMeteringPoints] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // the following line is just for tests
            //const date = new Date();
            // gets for example Mon Jan 01 2018 00:00:00 GMT+0100
            const filter = periodType === "DEVICE_CHANGE" ? {letztesEichJahr: filterDate} : {endStandDatum: filterDate};
            
            setMeteringPoints((await meteringPointsStore.load({filter: filter})).data);
            // reset the selected meteringPoints in the form
            setFormData({...formData, selectedDevices: []});
        }
        fetchData();
    }, [filterDate]); // Or [] if effect doesn't need props or state

    const onChange = (event, name) => {
        if (!name) { // TextBox or similar
            let {name, value} = event.event.target;
            setFormData({...formData, [name]: value});
        } else { // SelectBox
            setFormData({...formData, [name]: event.value});
        }
    }

    const onSelectedItemKeysChange = (args) => {
        if (args.name === 'selectedItemKeys') {
            setFormData({...formData, selectedDevices: args.value});
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let new_data = {
            type: periodType,
            year: formData.year,
            comment: formData.comment,
            status: formData.status,
            meteringPoints: formData.selectedDevices,
        }
        if (!new_data.meteringPoints || new_data.meteringPoints?.length === 0) {
            notify("Bitte wählen Sie mind. einen Sensor aus der Liste aus.", "warning", 3000)
            e.cancel = true
            return;
        }
        await periodsStore.insert(new_data);
        onHiding();
    };

    const renderListItem = () => {
        return (
            <List
                dataSource={meteringPoints}
                height={"auto"}
                showSelectionControls={true}
                searchExpr="id"
                searchEnabled={true}
                searchMode={"contains"}
                selectionMode={"all"}
                selectAllMode={"allPages"}
                selectedItemKeys={formData.selectedDevices}
                onOptionChanged={onSelectedItemKeysChange}
                itemRender={(item) => <div>{item.edvObjAbgId} (Abg. Nr.: {item.abgabeNr}, EJ: {item.letztesEichJahr ? item.letztesEichJahr : t('addPeriodFormPopup.unknown')}, AJ: {item.endStandDatum ? new Date(item.endStandDatum).getFullYear() : t('addPeriodFormPopup.unknown')})</div>}
            />
        )
    }

    const renderContent = () =>  {
        return (
            <ScrollView>
                <form action="/create-period" method="post" onSubmit={handleSubmit}>
                    <Form id="form"
                          labelMode={'outside'}
                          formData={formData}
                          colCount={2}
                          showValidationSummary={true}>
                        <ButtonItem
                            colSpan={2}
                            buttonOptions={{
                                text: 'Periode erstellen',
                                icon: 'add',
                                type: 'success',
                                useSubmitBehavior: true,
                            }} />
                        <GroupItem caption={t('addPeriodFormPopup.groupItem1')} colSpan={1}>
                            <Item dataField="year"
                                  editorType="dxNumberBox"
                                  editorOptions={{
                                      min:1970,
                                      max: new Date().getFullYear(),
                                      showSpinButtons:true
                                  }}
                                  colSpan={1}
                                  onValueChanged={onChange}>
                                <RequiredRule/>
                                <Label text={t('addPeriodFormPopup.year')} />
                            </Item>
                            <SimpleItem colSpan={1}>
                                <Label text={t('addPeriodFormPopup.status')} />
                                <SelectBox
                                    dataSource={t('PeriodStatus', {returnObjects: true})}
                                    displayExpr="name"
                                    valueExpr="value"
                                    value={formData.status}
                                    onValueChanged={(e) => onChange(e, "status")}/>
                            </SimpleItem>
                            <SimpleItem dataField="comment" editorType="dxTextBox" editorOptions={{height: "200"}} colSpan={1} onValueChanged={onChange}>
                                <Label text={t('addPeriodFormPopup.comment')} />
                            </SimpleItem>
                            <EmptyItem/>
                        </GroupItem>
                        <GroupItem caption={t('addPeriodFormPopup.groupItem2')} colSpan={1}>
                            {periodType === "DEVICE_CHANGE" ?
                                <Item dataField="filterDate"
                                      editorType="dxNumberBox"
                                      editorOptions={{
                                          value: filterDate,
                                          min: 1970,
                                          showSpinButtons: true,
                                          onValueChanged: (e) => setFilterDate(e.value)
                                      }}
                                      colSpan={1}>
                                    <Label text={t('addPeriodFormPopup.filterDate1')}/>
                                </Item>
                                :
                                <Item dataField="filterDate"
                                      editorType="dxDateBox"
                                      editorOptions={{
                                          value: filterDate,
                                          min: 1970,
                                          max: new Date(),
                                          showSpinButtons: true,
                                          onValueChanged: (e) => setFilterDate(e.value)
                                      }}
                                      colSpan={1}>
                                    <Label text={t('addPeriodFormPopup.filterDate2')}/>
                                </Item>
                            }
                            <SimpleItem
                                render={renderListItem}
                            />
                        </GroupItem>
                    </Form>
                </form>
            </ScrollView>
        )
    }

    return (
        <div className={"AddPeriodFormPopup"}>
                <Popup
                    visible={true}
                    onHiding={onHiding}
                    contentRender={renderContent}
                    fullScreen={false}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    //width={300}
                    //height={280}
                />
        </div>
    );
}

export default AddPeriodFormPopup;
