import './PageHeader.css';
import React from "react";
import PropTypes from 'prop-types';
import {navigate} from "hookrouter";
import { Button } from 'devextreme-react/button';

function PageHeader({headerText, backButtonText}) {

    return (
        <div className={"PageHeader"}>
            <h1>{headerText}</h1>
            { backButtonText ?
                <Button
                    type="normal"
                    stylingMode="text"
                    text={backButtonText}
                    onClick={() => window.history.back()}
                />
                :
                null
            }
        </div>
    );
}

PageHeader.propTypes = {
    headerText: PropTypes.string.isRequired,
    backButtonText:PropTypes.string
}

export default PageHeader;