// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserRole = {
  "SUPER_ADMIN": "SuperAdmin",
  "ADMIN": "Admin",
  "TECHNICIAN": "Technician",
  "CITIZEN": "Citizen"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "DEACTIVATED": "DEACTIVATED"
};

const EnumEntityType = {
  "USER": "USER"
};

const PeriodType = {
  "DEVICE_CHANGE": "DEVICE_CHANGE",
  "DEVICE_READING": "DEVICE_READING"
};

const PeriodStatus = {
  "DONE": "DONE",
  "IN_PROGRESS": "IN_PROGRESS",
  "CREATED": "CREATED"
};

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "DIVERSE": "DIVERSE",
  "OTHER": "OTHER"
};

const CountryCode = {
  "A": "A"
};

const MeteringPointStatus = {
  "ACTIVE": "ACTIVE",
  "REMOVED": "REMOVED"
};

const TourTaskType = {
  "DEVICE_CHANGE": "DEVICE_CHANGE",
  "DEVICE_READING": "DEVICE_READING"
};

const TourTaskStatus = {
  "DONE": "DONE",
  "IN_PROGRESS": "IN_PROGRESS",
  "PENDING": "PENDING",
  "CREATED": "CREATED"
};

const TourStatus = {
  "DONE": "DONE",
  "ACCOMPLISHED_BY_TECHNICIAN": "ACCOMPLISHED_BY_TECHNICIAN",
  "IN_PROGRESS_BY_TECHNICIAN": "IN_PROGRESS_BY_TECHNICIAN",
  "ASSIGNED_TO_TECHNICIAN": "ASSIGNED_TO_TECHNICIAN",
  "PENDING": "PENDING",
  "CREATED": "CREATED"
};

const DeviceType = {
  "HRL_C_G3": "HRL_C_G3",
  "CORONA_MV": "CORONA_MV"
};

const DeviceStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "IN_STOCK": "IN_STOCK",
  "REPLACED": "REPLACED",
  "REMOVED": "REMOVED"
};

const StockLocationStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "REMOVED": "REMOVED"
};

const { User, Tour, Period, PeriodMeteringPoint, TourTask, MeteringPoint, Object, Customer, Image, StockLocation, Device, TBToken, Address, GeoLocation, S3Object } = initSchema(schema);

export {
  User,
  Tour,
  Period,
  PeriodMeteringPoint,
  TourTask,
  MeteringPoint,
  Object,
  Customer,
  Image,
  StockLocation,
  Device,
  UserRole,
  UserStatus,
  EnumEntityType,
  PeriodType,
  PeriodStatus,
  Gender,
  CountryCode,
  MeteringPointStatus,
  TourTaskType,
  TourTaskStatus,
  TourStatus,
  DeviceType,
  DeviceStatus,
  StockLocationStatus,
  TBToken,
  Address,
  GeoLocation,
  S3Object
};