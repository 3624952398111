import React, {useContext, useEffect, useState, useRef} from "react";
import '../../App.css';
import DataGrid, {
    HeaderFilter, SearchPanel, Editing,
    Popup, LoadPanel, Lookup, Toolbar, Item as TItem,
    Form, Column, ColumnChooser, Sorting
} from 'devextreme-react/data-grid';
import SelectBox from "devextreme-react/select-box";
import {
    Item, RequiredRule,
} from 'devextreme-react/form';
import AuthContext from "../../contexts/AuthContext";
import "devextreme-react/select-box";
import {Scrolling} from "devextreme-react/tree-list";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import { DataStore, Hub } from "aws-amplify";
import { MeteringPoint, UserRole } from "../../models";
import {useTranslation} from "react-i18next";
import {meteringPointsStore, handleSubscriptionEvent} from '../../data/meteringPointsStore';
import {customersStore} from '../../data/customersStore';
import {objectsStore} from '../../data/objectsStore';
import { CheckBox } from 'devextreme-react/check-box';
import QrCodeScannerTextBox from '../QrCodeScannerTextBox';
import PageHeader from "../PageHeader/PageHeader";
import ImagesItem from "../ImagesItem/ImagesItem";

function MeteringPointManagementView() {
    loadMessages(deMessages);
    locale(navigator.language);
    const [t] = useTranslation();
    const userContext = useContext(AuthContext);
    const [editing, setEditing] = useState(false);
    const [filter, setFilter] = useState([]);
    const datagrid = useRef();

    const allowDeleting = (e) => {
        // SuperAdmin can edit anything
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const allowEditing = (e) => {
        // SuperAdmin can edit anything except other SUPER_ADMIN
        if (UserRole.SUPER_ADMIN === userContext.userInfoFromDB.role) {
            return true;
        }
        return [UserRole.ADMIN].includes(userContext.userInfoFromDB.role);
    }

    const addMeteringPoint = async (event) => {
        console.log(event)
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine MeteringPointen anlegen`);
            event.cancel = true;
            return;
        }
    }

    const editMeteringPoint = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine MeteringPointen bearbeiten`);
            event.cancel = true;
            return;
        }
    }

    const removeMeteringPoint = async (event) => {
        if (![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB.role)) {
            alert(`Benutzer mit Rolle ${userContext.userInfoFromDB.role} dürfen keine MeteringPointen löschen`);
            event.cancel = true;
            return;
        }
    }

    const onInitNewRow = (event) => {
        // set here default values
        setEditing(false);
        event.data.temporaryPlacement=false;
    }

    useEffect(() => {
        const hubListenerDS = async (hubData) => {
            const  { event } = hubData.payload;
            if (event === 'ready') {
                datagrid?.current?.instance?.refresh(true);
            }
        };
        Hub.listen('datastore', hubListenerDS);
        const subscription = DataStore.observe(MeteringPoint).subscribe(handleSubscriptionEvent);
        return () => {
            subscription.unsubscribe();
            Hub.remove('datastore', hubListenerDS);
        }
    }, []);

    if (userContext.userInfoFromDB && [UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(userContext.userInfoFromDB?.role)) {
        return (
            <div className="MeteringPointManagementView">
                <PageHeader headerText={t('meteringPointManagementView.header')} />
                <DataGrid
                    ref={ref => datagrid.current = ref}
                    dataSource={meteringPointsStore}
                    defaultFilterValue={[]}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={true}
                    allowColumnResizing={true}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={{
                        paging: true,
                        sorting: false,
                        filtering: false
                    }}
                    id="meteringPoints"
                    onRowInserting={e => addMeteringPoint(e)}
                    onRowUpdating={e => editMeteringPoint(e)}
                    onInitNewRow={e => onInitNewRow(e)}
                    onEditingStart={() => {setEditing(true)}}
                    onRowRemoving={e => removeMeteringPoint(e)}
                >
                    <ColumnChooser enabled={false} mode={"select"}/>
                    <LoadPanel enabled/>
                    <Sorting mode="single" />
                    <Scrolling mode="infinite" />
                    <SearchPanel visible={true} width={"auto"}/>
                    <HeaderFilter visible={true}/>
                    <Toolbar>
                        <TItem name="addRowButton" location={"after"} locateInMenu={"auto"}/>
                        <TItem
                            name="columnChooserButton"
                            locateInMenu="auto"
                            location="after"
                        />
                        <TItem name="searchPanel" location={"after"} locateInMenu={"auto"}/>
                    </Toolbar>
                        <Editing
                            mode={"popup"}
                            useIcons={true}
                            allowAdding={true}
                            allowUpdating={allowEditing}
                            allowDeleting={allowDeleting}
                            >
                            <Popup title={!editing ? t('meteringPointManagementView.popupTitle1') : t('meteringPointManagementView.popupTitle2')}
                                   showTitle={true}
                                   width="auto"
                                   //minWidth={"50vw"}
                                   maxHeight={"100vh"}
                                   height="auto"/>
                            <Form labelMode={'outside'} colCount={2} showValidationSummary={true}>
                                <Item dataField="abgabeNr" colSpan={1}></Item>
                                <Item dataField="abgabeLfdNr" colSpan={1}></Item>
                                <Item dataField="betriebsNr" colSpan={1}></Item>
                                <Item dataField="bauJahr" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:1970,
                                          showSpinButtons:true
                                      }}></Item>
                                <Item dataField="letztesEichJahr" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:1970,
                                          showSpinButtons:true
                                      }}></Item>
                                <Item dataField="eichIntervall" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0,
                                          showSpinButtons:true
                                      }}></Item>
                                <Item dataField="anzahlStellen" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0,
                                          showSpinButtons:true
                                      }}></Item>
                                <Item dataField="fabrikat" colSpan={1}></Item>
                                <Item dataField="einbauDatum" colSpan={1}></Item>
                                <Item dataField="wasserGenossenschaft" colSpan={1}></Item>
                                <Item dataField="versorgungsKreis" colSpan={1}></Item>
                                <Item dataField="anschlussDatum" colSpan={1}></Item>
                                <Item dataField="buchNr" colSpan={1}></Item>
                                <Item dataField="buchNrLfd" colSpan={1}></Item>
                                <Item dataField="durchFlussGroesse" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0.0
                                      }}></Item>
                                <Item dataField="zaehlerArt" colSpan={1}></Item>
                                <Item dataField="anfangsStand" colSpan={1}  editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0.0
                                      }}></Item>
                                <Item dataField="anfangsStandDatum" colSpan={1}></Item>
                                <Item dataField="endStand" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0.0
                                      }}></Item>
                                <Item dataField="endStandDatum" colSpan={1}></Item>
                                <Item dataField="webAblesung" colSpan={1}></Item>
                                <Item dataField="zlrVerbrLetztPer" colSpan={1} editorType="dxNumberBox"
                                      editorOptions={{
                                          min:0.0
                                      }}></Item>
                                <Item dataField="customerId" colSpan={1}/>
                                <Item
                                    dataField="images"
                                    colSpan={1}/>
                            </Form>
                        </Editing> : null}
                    <Column dataField="edvObjAbgId"
                            caption={t('meteringPointManagementView.edvObjAbgId')}
                            dataType={"string"}
                            defaultSortOrder={"asc"}
                            hidingPriority={50}
                    />
                    <Column dataField="abgabeNr"
                            caption={t('meteringPointManagementView.abgabeNr')}
                            dataType={"string"}
                            hidingPriority={50}
                            editCellRender={(cell) => <QrCodeScannerTextBox cell={cell}/>}
                    />
                    <Column dataField="abgabeLfdNr"
                            caption={t('meteringPointManagementView.abgabeLfdNr')}
                            dataType={"string"}
                            hidingPriority={49}>
                    </Column>
                    <Column dataField="betriebsNr"
                            caption={t('meteringPointManagementView.betriebsNr')}
                            dataType={"string"}
                            hidingPriority={48}>
                    </Column>
                    <Column dataField="buchNr"
                            caption={t('meteringPointManagementView.buchNr')}
                            dataType="number"
                            hidingPriority={47}/>
                    <Column dataField="buchNrLfd"
                            caption={t('meteringPointManagementView.buchNrLfd')}
                            dataType="buchNrLfd"
                            hidingPriority={46}/>
                    <Column dataField="bauJahr" caption={t('meteringPointManagementView.bauJahr')}
                            dataType="number"
                            hidingPriority={43}/>
                    <Column dataField="letztesEichJahr" caption={t('meteringPointManagementView.letztesEichJahr')}
                            dataType="number"
                            hidingPriority={42}/>
                    <Column dataField="eichIntervall"
                            caption={t('meteringPointManagementView.eichIntervall')}
                            dataType="number"
                            hidingPriority={41}/>
                    <Column dataField="anzahlStellen"
                            caption={t('meteringPointManagementView.anzahlStellen')}
                            dataType="number"
                            hidingPriority={40}/>
                    <Column dataField="fabrikat" caption={t('meteringPointManagementView.fabrikat')}
                            dataType="string"
                            hidingPriority={39}/>
                    <Column dataField="einbauDatum" caption={t('meteringPointManagementView.einbauDatum')}
                            dataType="date"
                            hidingPriority={38}/>
                    <Column dataField="wasserGenossenschaft" caption={t('meteringPointManagementView.wasserGenossenschaft')} dataType="string" hidingPriority={1}/>
                    <Column dataField="versorgungsKreis"
                            caption={t('meteringPointManagementView.versorgungsKreis')}
                            dataType="string"
                            hidingPriority={37}/>
                    <Column dataField="anschlussDatum"
                            caption={t('meteringPointManagementView.anschlussDatum')}
                            dataType="date"
                            hidingPriority={36}/>
                    <Column dataField="durchFlussGroesse"
                            caption={t('meteringPointManagementView.durchFlussGroesse')}
                            dataType="number"
                            hidingPriority={35}/>
                    <Column dataField="zaehlerArt"
                            caption={t('meteringPointManagementView.zaehlerArt')}
                            dataType="string"
                            hidingPriority={34}/>
                    <Column dataField="anfangsStand"
                            caption={t('meteringPointManagementView.anfangsStand')}
                            dataType="number"
                            hidingPriority={33}/>
                    <Column dataField="anfangsStandDatum"
                            caption={t('meteringPointManagementView.anfangsStandDatum')}
                            dataType="date"
                            hidingPriority={31}/>
                    <Column dataField="endStand"
                            caption={t('meteringPointManagementView.endStand')}
                            dataType="number"
                            hidingPriority={30}/>
                    <Column dataField="endStandDatum"
                            caption={t('meteringPointManagementView.endStandDatum')}
                            dataType="date"
                            hidingPriority={29}/>
                    <Column dataField="webAblesung"
                            caption={t('meteringPointManagementView.webAblesung')}
                            dataType="boolean"
                            hidingPriority={28}/>
                    <Column dataField="zlrVerbrLetztPer"
                            caption={t('meteringPointManagementView.zlrVerbrLetztPer')}
                            dataType="number"
                            hidingPriority={27}/>
                    <Column dataField="objectId"
                            caption={t('meteringPointManagementView.objectId')}
                            dataType={"string"}
                            hidingPriority={45}>
                        <Lookup dataSource={objectsStore} valueExpr="id" displayExpr={"objectId"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="customerId"
                            caption={t('meteringPointManagementView.customer')}
                            dataType={"string"}
                            hidingPriority={45}>
                        <Lookup dataSource={customersStore} valueExpr="id" displayExpr={"name"} searchEnabled={true}/>
                    </Column>
                    <Column dataField="deviceId"
                            caption={t('meteringPointManagementView.deviceId')}
                            dataType={"string"}
                            hidingPriority={45}/>
                    <Column dataField="images"
                            caption={t('meteringPointManagementView.images')}
                            hidingPriority={26}
                            editCellRender={(cell) => <ImagesItem cell={cell}/>}
                            visible={false}
                    >
                    </Column>
                </DataGrid>
            </div>
        );
    } else {
        return null;
    }
}

export default MeteringPointManagementView;
